import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Pressable,
  TouchableOpacity,
  TextInput,
  Modal,
  useWindowDimensions,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from "twrnc";
import moment from "moment";

import { IconButton } from "../../components/IconButton";
import {
  eliminarLicencia,
  exportarLicencias,
  getLicencias,
} from "../../services/apiClient";
import DataTable from "../../components/DataTable/DataTable";
import LicenciaEstado from "../../components/Licencias/LicenciaEstado";
import { awesomeAlertAtom, userAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import ResultadoModal from "../../components/ResultadoModal";
import AwesomeAlert from "react-native-awesome-alerts";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { esWeb } from "../../utils/plataforma";
import { Loader } from "../../components/Loader";
import FiltrosLicencias from "../../components/FiltrosLicencias";

export default function AdministrarLicenciasScreen() {
  const navigator = useNavigation();
  const licenciasQuery = useQuery("licencias", getLicencias);
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const [buscar, setBuscar] = useState("");
  const [licencias, setLicencias] = useState([]);
  const [modalFiltrarVisible, setModalFiltrarVisible] = useState(false);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const [color, setColor] = useState();
  const [licenciaId, setlicenciaId] = useState();
  const queryClient = useQueryClient();
  const [filtros, setfiltros] = useState();
  const [mostrarLimpiarFiltros, setmostrarLimpiarFiltros] = useState(false);
  const { width } = useWindowDimensions();
  const [user, _] = useAtom(userAtom);

  const checkPermisos = (listaSlugPermisos) => {
    const tienePermiso = user.permisos.some((permiso) =>
      listaSlugPermisos.includes(permiso.slug)
    );

    return tienePermiso;
  };

  useEffect(() => {
    if (buscar.length) {
      const busqueda = licenciasQuery.data?.data?.licencias.filter((licencia) =>
        licencia.solicitante?.apellido
          .toLowerCase()
          .includes(buscar.toLowerCase())
      );
      setLicencias(busqueda);
    }

    if (filtros) {
      let busqueda = licenciasQuery.data?.data?.licencias;
      Object.entries(filtros).forEach(([key, value]) => {
        busqueda = licenciasQuery.data?.data?.licencias.filter((licencia) => {
          if (key === "estado_id" && value === 0) {
            const { estado_id, ...rest } = filtros;
            setfiltros(rest);
          } else {
            return licencia[key] === parseInt(value);
          }
        });
        setLicencias(busqueda);
      });
    }

    if (buscar === "" && licenciasQuery.data?.data?.licencias && !filtros) {
      setLicencias(licenciasQuery.data.data.licencias);
    }
  }, [buscar, filtros]);

  // ELIMINAR LICENCIA

  const eliminar = (licenciaId) => {
    setawesomeAlert({
      show: true,
      showProgress: eliminarLicenciaMutate.isLoading,
      title: "Atención",
      message: "¿Esta seguro de desea eliminar?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        eliminarLicenciaMutate.mutate(licenciaId);
      },
    });
  };
  const eliminarLicenciaMutate = useMutation(eliminarLicencia, {
    onSuccess: async (response) => {
      console.log("licencia eliminada", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message="Licencia eliminada" />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("licencias");
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar eliminar",
        error.response.data.message
      );
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error.response.data.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const exportarLicenciasMutate = useMutation(
    (query) => exportarLicencias(query),
    {
      onSuccess: (response) => {
        if (esWeb()) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "licencias.xlsx");
          document.body.appendChild(link);
          link.click();
        }
        //TODO: agregar un metodo para android e ios
      },
    }
  );

  //let licencias = [];
  useEffect(() => {
    if (licenciasQuery.data && licenciasQuery.data.data?.ok) {
      const licenciasNoEliminadas = licenciasQuery.data.data.licencias.filter( licencia => licencia.deleted_at === null );
      setLicencias(licenciasNoEliminadas);
    }
  }, [licenciasQuery.data]);

  const getLicencia = (licenciaId) => {
    const licencia = licencias.filter((licencia) => licencia.id === licenciaId);
    if (licencia.length) {
      return licencia[0];
    }
    console.log("No se encontro la licencia para el id ", licenciaId);
    return;
  };

  const setearColor = (estado) => {
    switch (estado?.toString()) {
      case "AUTORIZADO":
        return Colores.success;
        break;
      case "NO AUTORIZADO":
        return Colores.danger;
        break;
      default:
        return Colores.dark;
        break;
    }
  };

  const prepararQuery = () => {
    let queryString = "";
    if (filtros) {
      Object.entries(filtros).map(
        ([filtro, valor]) => (queryString += `${filtro}=${valor}&`)
      );
    }
    return queryString;
  };

  useEffect(() => {
    if (buscar.length || filtros) {
      setmostrarLimpiarFiltros(true);
    }
  }, [buscar, filtros]);

  const limpiarFiltros = () => {
    setBuscar("");
    setfiltros();
    setmostrarLimpiarFiltros(false);
  };

  const colorCardLicencia = (estadoLicencia) => {
    switch (estadoLicencia) {
      case 2:
        return Colores.successBg;
        break;
      case 3:
        return Colores.dangerBg;
        break;
      default:
        return Colores.gray;
        break;
    }
  };

  return (
    <>
      <PageContainer paddingMd>
        <View style={[tw`bg-white p-5 flex-1`, { borderRadius: 20 }]}>
          <Text style={tw`text-lg font-semibold text-gray-500 mb-1 ml-1`}>
            Licencias
          </Text>
          <View style={tw`items-center my-2`}>
            {exportarLicenciasMutate.isLoading ? (
              <Loader text="descargando" />
            ) : (
              <TouchableOpacity
                onPress={() => {
                  const query = prepararQuery();
                  exportarLicenciasMutate.mutate(query);
                }}
                style={[
                  tw`px-10 py-2 justify-center flex-row`,
                  {
                    borderRadius: 15,
                    color: Colores.primaryStrong,
                    backgroundColor: Colores.primaryLight,
                  },
                ]}
              >
                <View style={tw`justify-center mr-1`}>
                  <Ionicons
                    name="document-attach-outline"
                    size={20}
                    color={Colores.primaryStrong}
                  />
                </View>
                <View style={tw`justify-center ml-1`}>
                  <Text style={{ color: Colores.primaryStrong }}>
                    Descargar listado de licencias
                  </Text>
                </View>
              </TouchableOpacity>
            )}
          </View>
          <View style={tw`flex flex-row my-2`}>
            <View style={tw`w-full flex flex-row justify-around my-2`}>
              <View style={tw`flex flex-1 justify-center mr-2`}>
                <TextInput
                  style={[
                    tw` w-full px-3 py-2.5 text-gray-700 border border-solid border-gray-300`,
                    { borderRadius: 23 },
                    esWeb() ? tw`shadow-md ` : "",
                  ]}
                  placeholder="Buscar"
                  onChangeText={(text) => setBuscar(text)}
                  placeholderTextColor={"#7D7D7D"}
                  value={buscar}
                />
                <AntDesign
                  name="search1"
                  size={20}
                  color={"#7D7D7D"}
                  style={tw`absolute right-4`}
                />
              </View>
              <View style={tw`flex items-center justify-center`}>
                <TouchableOpacity
                  style={[
                    tw`rounded-full p-2`,
                    { backgroundColor: Colores.primaryLight },
                  ]}
                  onPress={() => setModalFiltrarVisible(true)}
                >
                  <Ionicons name="filter" size={24} color={Colores.primary} />
                </TouchableOpacity>
              </View>
              {mostrarLimpiarFiltros && (
                <View>
                  <TouchableOpacity
                    style={[
                      tw`py-2 px-5 ml-1 flex items-center justify-center`,
                      {
                        backgroundColor: Colores.primary,
                        borderRadius: 16,
                      },
                    ]}
                    onPress={limpiarFiltros}
                  >
                    <MaterialIcons
                      name="cleaning-services"
                      size={20}
                      color="white"
                    />
                    <Text style={tw`text-white`}>Limpiar filtros</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>

            <View style={tw`flex-1 justify-center items-center`}>
              <Modal
                animationType="fade"
                transparent={true}
                visible={modalFiltrarVisible}
                onRequestClose={() => {
                  setModalFiltrarVisible(!modalFiltrarVisible);
                }}
              >
                <View
                  style={[
                    width >= 768
                      ? tw`flex-1 justify-center items-center p-5`
                      : tw`flex-1 justify-center p-5`,
                    { backgroundColor: "#7D7D7D50" },
                  ]}
                >
                  <View style={[tw`bg-white p-5`, { borderRadius: 10 }]}>
                    <View style={tw`flex-row`}>
                      <View style={tw`flex-1 justify-center`}>
                        <Text style={tw`text-lg`}>Filtrar por:</Text>
                      </View>
                      <View style={tw`flex-1 items-end justify-center`}>
                        <TouchableOpacity onPress={() => setfiltros()}>
                          <Text style={[tw``, { color: Colores.primary }]}>
                            Limpiar filtros
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View>
                      <FiltrosLicencias
                        filtros={filtros}
                        setfiltros={setfiltros}
                      />

                      <View
                        style={tw`flex-row items-center justify-center mt-5`}
                      >
                        <TouchableOpacity
                          style={[
                            tw`py-2 px-10 mr-1`,
                            {
                              backgroundColor: Colores.primaryMedium,
                              borderRadius: 16,
                            },
                          ]}
                          onPress={() => {
                            setModalFiltrarVisible(!modalFiltrarVisible);
                            limpiarFiltros();
                          }}
                        >
                          <Text style={tw`text-white`}>Salir</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                          style={[
                            tw`py-2 px-10 ml-1`,
                            {
                              backgroundColor: Colores.primary,
                              borderRadius: 16,
                            },
                          ]}
                          onPress={() => {
                            setModalFiltrarVisible(!modalFiltrarVisible);
                          }}
                        >
                          <Text style={tw`text-white`}>Aplicar</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </Modal>
            </View>
          </View>
          <View style={tw`flex-1`}>
            <FlatList
              data={licencias}
              keyExtractor={(item) => item.id}
              contentContainerStyle={{ paddingBottom: 20 }}
              renderItem={({ item }) => (
                <View
                  style={[
                    tw`mt-2 p-3 flex flex-row rounded-lg w-full border border-gray-200 shadow-md`,
                    {
                      borderRadius: 16,
                      backgroundColor: colorCardLicencia(item.estado_id),
                    },
                  ]}
                >
                  <View style={[tw`flex-1`]}>
                    <Pressable
                      onPress={() => {
                        const licencia = getLicencia(item.id);
                        navigator.navigate("Editar Licencia", { licencia });
                      }}
                    >
                      <Text style={tw`capitalize font-semibold`}>
                        {`${item.solicitante?.apellido} ${item.solicitante?.nombre}`}
                      </Text>

                      <Text style={tw`font-semibold mt-1`}>
                        {item.empresa?.nombre}
                      </Text>

                      <View style={tw`flex-row mt-1`}>
                        <Text style={tw`font-semibold`}>Tipo:</Text>
                        <Text style={tw`text-gray-600 ml-1`}>
                          {item?.opcion?.opcion}
                        </Text>
                      </View>

                      <View style={tw`flex-row mt-1`}>
                        <Text style={tw`font-semibold`}>Creada el:</Text>
                        <Text style={tw`text-gray-600 ml-1`}>
                          {moment(item.created_at)
                            .locale("es")
                            .format("l [a las] HH:mm")}
                        </Text>
                      </View>

                      <View style={tw`flex flex-row mt-1`}>
                        <View style={tw`flex-row`}>
                          <Text style={tw`font-semibold`}>Inicio:</Text>
                          <Text style={tw`ml-1 text-gray-600`}>
                            {moment(item?.inicio).format("DD/MM/YYYY")}
                          </Text>
                        </View>

                        <View style={tw`flex-row`}>
                          <Text style={tw`ml-5 font-semibold`}>Fin:</Text>
                          <Text style={tw`ml-1 text-gray-600`}>
                            {moment(item?.fin).format("DD/MM/YYYY")}
                          </Text>
                        </View>
                      </View>

                      <View style={tw`flex flex-row mt-1`}>
                        <View style={tw`flex-row`}>
                          <Text style={tw`font-semibold`}>Dias:</Text>
                          <Text style={tw`ml-1 text-gray-600`}>
                            {item?.dias}
                          </Text>
                        </View>
                        {item.horario && item.horario !== "undefined" && (
                          <Text style={tw`ml-10`}>Horario: {item?.horario}</Text>
                        )}
                      </View>
                      <View style={tw`flex-row mt-1`}>
                        <Text style={tw`font-semibold`}>Estado:</Text>
                        <Text
                          style={[
                            tw`ml-1`,
                            { color: setearColor(item?.estado?.estado) },
                          ]}
                        >
                          {item?.estado?.estado.charAt(0).toUpperCase() +
                            item?.estado?.estado.slice(1).toLowerCase()}
                        </Text>
                      </View>
                      {item.estado_id !== 1 && (
                        <Text style={tw`capitalize font-semibold`}>
                          por{" "}
                          {`${item.autorizante?.nombre} ${item.autorizante?.apellido}`}
                        </Text>
                      )}

                      {checkPermisos(["administrar.todo"]) ? (
                        <TouchableOpacity
                          onPress={() => eliminar(item.id)}
                          style={tw`p-1 rounded-md mt-1 flex flex-row items-center justify-center w-1/4 bg-red-400`}
                        >
                          <Feather
                            name="trash"
                            size={22}
                            color={Colores.danger}
                          />
                          <Text
                            style={tw`capitalize font-semibold text-red-600`}
                          >
                            Eliminar
                          </Text>
                        </TouchableOpacity>
                      ) : null}
                    </Pressable>
                  </View>
                  <View style={[tw`flex flex-col justify-center`]}>
                    <AntDesign name="right" size={24} color="black" />
                  </View>
                </View>
              )}
            />
          </View>
        </View>
      </PageContainer>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
      />
    </>
  );
}
