import React, { useState } from "react";
import { FlatList, Text, TextInput, TouchableOpacity, View } from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";
import { getSanciones } from "../../services/apiClient";
import { useQuery } from "react-query";
import moment from "moment";
import { AntDesign } from '@expo/vector-icons';

function AdministrarSancionesScreen(props) {

  const [filtro, setFiltro] = useState()
  const sanciones = useQuery("sanciones",getSanciones);
    
  return (
    <>
    <PageContainer paddingMd >
      <View style={[tw`bg-white flex-1 h-full p-5`,{borderRadius:20}]}>
        
          <View style={tw`mt-5`}>
            <View style={tw`flex-row`}>
              <View style={tw`flex-1`}>
                <Text style={tw`text-lg font-semibold`}>Sanciones</Text>
              </View>
              <View style={tw`flex-1 items-end`}>
                <TouchableOpacity onPress={()=>props.navigation.navigate('Nueva Sancion')} style={[tw`border border-gray-200 flex-row py-3`,{borderRadius:10}]}>
                  <View style={[tw`justify-center pl-7 pr-5 py-1`,{borderLeftWidth:2, borderColor:Colores.danger}]}>
                    <Text style={tw`text-gray-700 text-center`}>Nueva Sancion</Text>
                  </View>
                  <View style={tw`justify-center`}>
                    <AntDesign name="warning" size={20} color="gray" style={tw`mr-5`} />
                  </View>
                </TouchableOpacity>   
              </View>
            </View>

            <View style={tw`justify-center mt-5`}>
              <TextInput
                style={[tw`form-control shadow-md w-full px-3 py-2.5 text-gray-700 border border-solid border-gray-300`,{borderRadius:23}]}
                placeholder="Buscar colaborador"
                onChangeText={
                    (text) =>{
                     searchFilterFunction(text);
                     setFiltro(text);
                   }}
                placeholderTextColor={'#7D7D7D'}
              />
              <AntDesign name="search1" size={20} color={'#7D7D7D'} style={tw`absolute right-4`}/>
            </View>

            <View style={tw`border border-gray-300 mt-3 rounded-xl`}>
              <View style={[tw`flex-row bg-gray-100 p-5`, {borderBottomWidth:1, borderColor:'#C7C7C7', borderTopLeftRadius:10, borderTopRightRadius:10}]}>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Colaborador</Text>
                </View>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Empresa</Text>
                </View>

                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Fecha</Text>
                </View>
            </View>

            <FlatList
                data={filtro ? filteredData : sanciones}
                keyExtractor={(item) => item?.id}
                renderItem={({item})=>(
                    <View style={[tw`flex-row p-5`,{borderBottomWidth:1, borderColor:'#C7C7C7'}]}>
                      <View style={tw`flex-1 flex-row justify-center items-center`}>
                        {/* <Image source={require('../../assets/avatar-prueba.png')} style={tw`w-6 h-6 mr-2`} /> */}
                        <Text style={tw`text-center`}>{`${item.colaborador.nombre} ${item.colaborador.apellido}`}</Text>
                      </View>
                      <View style={tw`flex-1 justify-center`}>
                        <Text style={tw`text-center`}>{item.empresa.nombre}</Text>
                      </View>
                      <View style={tw`flex-1 justify-center`}>
                        <Text style={tw`text-center capitalize`}>{moment(item?.periodo).locale('es').format('MMMM')}</Text>
                      </View>
                    </View>
                )
                }
            />
            </View>
          </View>

      </View>
    </PageContainer>
    </> 
  );
}

export default AdministrarSancionesScreen;
