import { View, Text, TouchableOpacity, TextInput, FlatList } from 'react-native'
import React, { useState } from 'react'
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {descargarDocumento, getConstancias } from '../../services/apiClient';
import { PageContainer } from '../../components/PageContainer';
import { Colores } from '../../constants/colores';
import { esWeb } from "../../utils/plataforma";

const AdministrarConstanciasScreen = () => {
  const [buscador, setBuscador] = useState()
  const [filtro, setFiltro] = useState()
  const [filteredData, setFilteredData] = useState([])
  const queryClient = useQueryClient();


  const constancias = useQuery(
    "constancias",
    getConstancias
);

  // const searchFilterFunction = (text) =>{
  //   if(text){
  //       const newData = recibos.filter(item => {
  //         const itemData = item.colaborador ? `${item.colaborador.nombre.toUpperCase()} ${item.colaborador.apellido.toUpperCase()}` : ''.toLocaleUpperCase();
  //         const textData = text.toUpperCase();
  //         return itemData.indexOf(textData) > -1;
  //       })
  //       setFilteredData(newData)
  //   }else{
  //     setFilteredData(recibos)
  //   }
  // }

    const descargarDoc = useMutation(descargarDocumento,{
    onSuccess: async (response) => {

      console.log(response)
      if(esWeb()){
        const inputMIME = response.headers["content-type"]
        const url = window.URL.createObjectURL(new Blob(response.data));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`certificado.${response.headers["content-type"].split('/')[1]}`);
        document.body.appendChild(link);
        link.click();
      }
      },
      onError: async (error) => {
        console.log(error.response.data.message);
      },
      onSettled: (data) => {
      },
    });

    const descargar = (item) =>{
      FileSystem.downloadAsync(
        item.archivos[0].archivos[0].archivo,
        FileSystem.documentDirectory + `certificado.${item.archivos[0].archivos[0].extension}`
      )
        .then(({ uri }) => {
          console.log('Finished downloading to ', uri);
        })
        .catch(error => {
          console.error(error);
        });
    }

    console.log(constancias.data?.data?.constancias)

  return (
    <PageContainer paddingMd>

          <View style={[tw`p-5 mt-5 bg-white`,{borderRadius:20}]}>
            <Text style={tw`text-lg font-semibold`}>Constancias</Text>

            <View style={tw`justify-center mt-5`}>
              <TextInput
                style={[tw`form-control shadow-md w-full px-3 py-2.5 text-gray-700 border border-solid border-gray-300`,{borderRadius:23}]}
                placeholder="Buscar colaborador"
                onChangeText={
                    (text) =>{
                     searchFilterFunction(text);
                     setFiltro(text);
                   }}
                placeholderTextColor={'#7D7D7D'}
              />
              <AntDesign name="search1" size={20} color={'#7D7D7D'} style={tw`absolute right-4`}/>
            </View>

            <View style={tw`mt-3 rounded-xl`}>
              <View style={[tw`flex-row bg-gray-200 p-5`, {borderBottomWidth:0, borderColor:'#C7C7C7', borderTopLeftRadius:10, borderTopRightRadius:10}]}>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Dni</Text>
                </View>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Colaborador</Text>
                </View>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Empresa</Text>
                </View>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Fecha</Text>
                </View>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-center font-semibold`}>Doc</Text>
                </View>
              </View>              

            </View>
            <FlatList
                    data={constancias.data?.data?.constancias}
                    keyExtractor={item => item.id}
                    renderItem={({item, index}) => (
                      <View style={[tw`border-gray-200`,{borderLeftWidth:1, borderRightWidth:1}]}>
                      <View style={[tw`flex-row p-5 border-gray-200`, {borderBottomWidth:1, borderTopLeftRadius:10, borderTopRightRadius:10}]}>
                        <View style={tw`flex-1`}>
                          <Text style={tw`text-center`}>{item.colaborador.dni}</Text>
                        </View>
                        <View style={tw`flex-1`}>
                          <Text style={tw`text-center capitalize`}>{`${item.colaborador.nombre} ${item.colaborador.apellido}`}</Text>
                        </View>
                        <View style={tw`flex-1`}>
                          <Text style={tw`text-center`}>{item.colaborador.empresa_actual[0].nombre}</Text>
                        </View>
                        <View style={tw`flex-1`}>
                          <Text style={tw`text-center`}>{moment(item?.created_at).locale('es').format('DD-MM-yyyy')}</Text>
                        </View>
                        <View style={tw`flex-1 items-center`}>
                          <TouchableOpacity style={[tw`py-2 px-3`,{borderRadius:15,backgroundColor:Colores.primaryLight}]} onPress={()=>descargarDoc.mutate(item.archivos[0].legajo_id)}>
                          {/* <TouchableOpacity style={[tw`py-2 px-3`,{borderRadius:15,backgroundColor:Colores.primaryLight}]} onPress={()=>descargar(item)}> */}
                            <AntDesign name="download" size={19} color={Colores.primaryStrong} />
                          </TouchableOpacity> 
                        </View>
                      </View>              
        
                      </View>
                    )}
                  />
          </View>
      </PageContainer>
  )
}

export default AdministrarConstanciasScreen