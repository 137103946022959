import { View, Text, Image, TouchableOpacity } from 'react-native'
import React, { useEffect, useState } from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import moment from 'moment'
import tw from 'twrnc';
import { getMisPronosticos } from '../../services/apiClient';
import { useQuery, useQueryClient } from 'react-query';

const CardPartido = ({color, info, pronosticarPartido}) => {
    const queryClient = useQueryClient();

    const misPronosticos = useQuery("misPronosticos",getMisPronosticos);

    const refrescarBotones = () =>{
        setTimeout(() => {
            queryClient.invalidateQueries("misPronosticos");
        }, 300);
    }

    const filtrar = () =>{
        const resultado = misPronosticos?.data?.data?.misPronosticos.filter((partido) => partido.fixture_id === info.id)
        return(resultado[0]?.pronostico)
    }
    

  return (
    <View style={[tw`border border-gray-200 mt-5`,{borderRadius:20}]}>
        <LinearGradient colors={color} end={{ x: 0.5, y: 0.1 }} style={{borderTopRightRadius:20, borderTopLeftRadius:20}}>
            <View style={tw`flex-row p-2`}>
                <View style={tw`flex-1`}>
                    <Text style={tw`capitalize text-white`}>{moment(info.fecha).format('dddd DD/MM')}</Text>
                </View>
                <View style={tw`flex-1 items-end`}>
                    <Text style={tw`text-white`}>{info.hora} (ARG)</Text>
                </View>
            </View>

            <View style={tw`px-2`}>
            <View style={{borderBottomWidth:1, borderBottomColor:'#FFF'}} />
            </View>

            <View style={[tw`flex-row p-2`]}>
                <View style={tw`flex-1 flex-row`}>
                    <View style={tw`flex-1 justify-center items-end`}>
                    <Image source={{uri:info.banderaLocal}} style={[tw`h-8 w-8 rounded-full mr-2`,{borderWidth:2, borderColor:'#FFF'}]} />
                    </View>
                    <View style={tw`flex-3 justify-center`}>
                    <Text style={tw`text-white uppercase`}>{info.local}</Text>
                    </View>
                </View>
                <View style={tw`flex-1 flex-row`}>
                    <View style={tw`flex-3 justify-center items-end`}>
                    <Text style={tw`text-white uppercase`}>{info.visitante}</Text>
                    </View>
                    <View style={tw`justify-center flex-1`}>
                    <Image source={{uri:info.banderaVisitante}} style={[tw`h-8 w-8 rounded-full ml-2`,{borderWidth:2, borderColor:'#FFF'}]} />
                    </View>
                </View>
            </View>
        </LinearGradient>

        <View style={[tw`flex-row p-2`,{backgroundColor:'#F0F0F0',borderBottomLeftRadius:20, borderBottomRightRadius:20}]}>
            {misPronosticos?.data?.data?.misPronosticos && (
            <>
                <View style={tw`flex-1 items-center justify-center`}>
                    <TouchableOpacity style={[tw`px-3 my-1 py-1`,{backgroundColor:filtrar() === "L" ? '#A12F51' : '#D9D9D9' , borderWidth: filtrar() === "L" && 2, borderColor: filtrar() === "L" && '#FA5586', borderRadius:15}]} onPress={()=>{pronosticarPartido(info.id, "L")}}>
                        <Text style={filtrar() === "L" && tw`text-white`}>LOCAL</Text>
                    </TouchableOpacity>
                </View>
                <View style={tw`flex-1 items-center justify-center`}>
                    <TouchableOpacity style={[tw`px-3 my-1 py-1`,{backgroundColor:filtrar() === "E" ? '#A12F51' : '#D9D9D9',borderWidth:filtrar() === "E" && 2,borderColor: filtrar() === "E" && '#FA5586', borderRadius:15}]} onPress={()=>{pronosticarPartido(info.id, "E")}}>
                        <Text style={filtrar() === "E" && tw`text-white`}>EMPATE</Text>
                    </TouchableOpacity>
                </View>
                <View style={tw`flex-1 items-center justify-center`}>
                    <TouchableOpacity style={[tw`px-3 my-1 py-1`,{backgroundColor:filtrar() === "V" ? '#A12F51' : '#D9D9D9',borderWidth:filtrar() === "V" &&2,borderColor: filtrar() === "V" && '#FA5586', borderRadius:15}]} onPress={()=>{pronosticarPartido(info.id, "V")}}>
                        <Text style={filtrar() === "V" && tw`text-white`}>VISITANTE</Text>
                    </TouchableOpacity>
                </View>
            </>
            )}
        </View>
      </View>
  )
}

export default CardPartido