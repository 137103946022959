import React, { useEffect, useState } from "react";
import { View } from "react-native";
import tw from "twrnc";
import AsignarItemSteps from "../components/Items/AsignarItemSteps";
import ButtonsSteps from "../components/Items/ButtonsSteps";
import { FormProvider, useForm } from "react-hook-form";
import ContentStep1 from "../components/Items/ContentStep1";
import ContentStep2 from "../components/Items/ContentStep2";
import ContentStep3 from "../components/Items/ContentStep3";
import ContentStep4 from "../components/Items/ContentStep4";
import { useAtom } from "jotai";
import { currentStepAtom } from "../store/store";
import { useMutation, useQueryClient } from "react-query";
import { asignarItem, editarItem } from "../services/apiClient";
import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import { Colores } from "../constants/colores";
import { Loader } from "../components/Loader";


const alertInitialState = {
  show: false,
  title: "",
  message: "",
  showConfirmButton: false,
  url: "",
};

const ItemFormSteps = (props) => {
  const { formData, route } = props;
  const item = route?.params?.item;

  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(item ?? {});
  const totalSteps = 4;

  const mutateOptions = {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: async (response) => {
      setIsLoading(false);
      resetForm();
      queryClient.invalidateQueries("items");
      const message = item
        ? "El activo fue atualizado"
        : "El activo fue asignado";
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: <ResultadoModal success={true} message={message} />,
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      setIsLoading(false);
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
      });
    },
  };

  const asignarItemMutate = useMutation(asignarItem, mutateOptions);
  const editarItemMutate = useMutation(editarItem, mutateOptions);

  useEffect(() => {
    if (item) {
      setInitialValues({
        titulo: item?.titulo,
        tipo_id: item?.tipo_id ?? 1,
        descripcion: item?.descripcion,
        cantidad: item?.cantidad,
        requiereConformidadRecepcion: item?.requiereConformidadRecepcion,
        requiereConformidadDevolucion: item?.requiereConformidadDevolucion,
        caduca: item?.caduca,
        fechaCaduca: item?.fechaCaduca ? new Date(item?.fechaCaduca) : null,
        asignado_id: item?.asignado ?? null,
      });
      setCurrentStep(1);
    }
  }, [item]);

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  console.log("initialValues", initialValues);

  const methods = useForm({
    reValidateMode: "onChange",
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const validaciones = {
    1: ["titulo", "tipo_id"],
    2: ["observaciones"],
    3: ["asignado_id"],
    4: [],
  };

  const validate = async () => {
    const isValid = await methods.trigger(validaciones[currentStep]);
    return isValid;
  };

  const resetForm = () => {
    methods.reset();
    setCurrentStep(1);
    for (const key of formData.keys()) {
      formData.delete(key);
    }
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          //envia solo el id del colaborador asignado
          if (key == "asignado_id") {
            //si se esta editando el item, no recibe un objeto sino el id
            formData.append(key, data[key].id);
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }
    if (item) {
      editarItemMutate.mutate({ id: item.id, formData });
    } else {
      asignarItemMutate.mutate(formData);
    }
  };

  const enviar = () => {
    onSubmit(methods.getValues());
  };

  return (
    <>
      <View style={tw`w-full h-full flex flex-col items-center gap-8`}>
        <AsignarItemSteps currentStep={currentStep} />
        <FormProvider {...methods}>
          <ContentStep1 formData={formData} currentStep={currentStep} />

          <ContentStep2
            formData={formData}
            currentStep={currentStep}
            item={item}
            setmostrarAlert={setmostrarAlert}
          />

          <ContentStep3 currentStep={currentStep} asignado={item?.asignado} />

          <ContentStep4
            formData={formData}
            currentStep={currentStep}
            item={item}
          />

          {isLoading ? (
            <View style={tw`p-2`}>
              <Loader text="Enviando" />
            </View>
          ) : (
            <ButtonsSteps
              totalSteps={totalSteps}
              validate={validate}
              submit={enviar}
              isLoading={isLoading}
            />
          )}
        </FormProvider>
      </View>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor={Colores.success}
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
          navigation.navigate("Administrar Items");
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          navigation.navigate("Administrar Items");
        }}
      />
    </>
  );
};

export default ItemFormSteps;