import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc"

export default function TituloItem({ titulo, children }) {
  if(!titulo) return null;
  return (
    <View style={tw`flex flex-row items-center justify-center gap-4`}>
      <Text style={tw`flex-1 text-lg font-semibold uppercase`}>{titulo}</Text>
      {children}
    </View>
  );
}