import { Image, Modal, Pressable, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import React, { useState } from 'react'
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome5 } from "@expo/vector-icons";
import ColaboradorDatosEmpresariales from './ColaboradorDatosEmpresariales';
import ColaboradorDatosDeContacto from './ColaboradorDatosDeContacto';

const validarNombreDatoLaboral = (colaboradorSeleccionado) => {
  let nombreEmpresa = '';
  let nombreDepartamento = '';
  let nombrePuesto = '';

  if(Array.isArray(colaboradorSeleccionado?.empresa_actual) && colaboradorSeleccionado?.empresa_actual.length){
    nombreEmpresa = colaboradorSeleccionado?.empresa_actual[0].nombre
  }else{
    nombreEmpresa = colaboradorSeleccionado?.empresa_actual?.nombre
  }

  if(Array.isArray(colaboradorSeleccionado?.departamento_actual) && colaboradorSeleccionado?.departamento_actual.length){
    nombreDepartamento = colaboradorSeleccionado?.departamento_actual[0].nombre
  }else{
    nombreDepartamento = colaboradorSeleccionado?.departamento_actual?.nombre
  }

  if(Array.isArray(colaboradorSeleccionado?.puesto_actual) && colaboradorSeleccionado?.puesto_actual.length){
    nombrePuesto = colaboradorSeleccionado?.puesto_actual[0].nombre
  }else{
    nombrePuesto = colaboradorSeleccionado?.puesto_actual?.nombre
  }

  return { nombreEmpresa, nombreDepartamento, nombrePuesto }
}

const ColaboradorModalDetalle = ({modalVisible, setModalVisible, colaboradorSeleccionado, inicio}) => {
    const {width} = useWindowDimensions()
   

  const { nombreEmpresa, nombreDepartamento, nombrePuesto } = validarNombreDatoLaboral(colaboradorSeleccionado);

  return (
    <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}>
            <View style={[tw`flex-1 flex-row`,{backgroundColor:'#7D7D7D50'}]} onPress={()=>setModalVisible(false)}>

                  {width >768 && ( <Pressable style={tw`flex-1`} onPress={()=>setModalVisible(false)}></Pressable>)}

                    <View style={[tw`bg-white flex-2`, width > 768 && {borderTopLeftRadius: 10}, {borderTopRightRadius:width > 768 ? 10 : 0}]}>
                      <LinearGradient style={[ width > 758 ? tw`w-full h-50 absolute top-0` : tw`w-full h-70 absolute top-0`,{borderTopRightRadius:width > 768 ? 10 : 0},StyleSheet.absoluteFill]} end={{ x: 0.5, y: 0.1 }} colors={['rgba(92, 91, 229, 0.26)','rgba(92, 91, 229, 0.98)']} />

                        <LinearGradient style={[ width > 758 ? tw`w-full h-50 absolute top-0` : tw`w-full h-70 absolute top-0`,{borderTopRightRadius:width > 768 && 10},StyleSheet.absoluteFill]} end={{ x: 0.5, y: 0.1 }} colors={['rgba(92, 91, 229, 0.26)','rgba(92, 91, 229, 0.98)']} />

                        {width < 758 && (
                          <View style={tw`flex-row mt-5`}>
                            <View style={tw`flex-1`}></View>
                            <View style={tw`flex-3`}><Text style={tw`text-center text-white font-semibold text-lg`}>Perfil</Text></View>
                            <View style={tw`flex-1`}></View>
                          </View>
                        )}

                        <View style={[tw`justify-center px-3 mt-10`, width > 758 &&  {alignItems: 'center'}]}>
                          <View style={tw`flex-row`}>
                              <View style={tw`px-5`}>
                                <Image style={tw`h-30 w-30 rounded-full border-2 border-white`} source={colaboradorSeleccionado?.foto ? {uri: colaboradorSeleccionado.foto} : require('../../assets/default.jpg')}/>
                              </View>

                              <View style={tw`p-3 flex-1`}>
                                <Text style={tw`text-lg text-white font-semibold mt-1 capitalize mb-2`}>
                                  {`${colaboradorSeleccionado?.nombre} ${colaboradorSeleccionado?.apellido}`}
                                </Text>
                                <View style={tw`flex flex-row items-center justify-start`}>
                                  <FontAwesome5 name="briefcase" size={11} color='#FFF' style={tw`mr-2`}  />
                                  <Text style={tw`text-white mt-1 capitalize`}>{nombrePuesto }</Text>
                                </View>
                                <View style={tw`flex flex-row items-center justify-start`}>
                                  <FontAwesome5 name="building" size={11} color='#FFF' style={tw`mr-2`}  />
                                  <Text style={tw`text-white mt-1 capitalize`}>{nombreEmpresa}</Text>
                                </View>
                              </View>
                          </View>
                        </View>

                        <View style={width > 768 ? tw`p-10` : tw`p-3` }>
                          <View style={ width > 758 ? tw`mt-7` : tw`mt-3` }>
                            <ColaboradorDatosDeContacto
                              datosPersonalesDisabled
                              emailCorporativo={colaboradorSeleccionado?.emailCorporativo}
                              emailPersonal={colaboradorSeleccionado?.emailPersonal}
                              telCorporativo={colaboradorSeleccionado?.telCorporativo}
                              telPersonal={colaboradorSeleccionado?.telPersonal}
                            />
                          </View>

                          <View style={ width > 758 ? tw`mt-7` : tw`mt-3` }>
                            <ColaboradorDatosEmpresariales
                              empresa={nombreEmpresa }
                              departamento={nombreDepartamento}
                              puesto={nombrePuesto}
                            />
                          </View>
                        </View>
    
                    </View>

                    <TouchableOpacity style={tw`absolute top-5 right-5`} onPress={()=>setModalVisible(false)}><AntDesign name="close" size={24} color="#FFF" /></TouchableOpacity>

                    {width >768 && ( <Pressable style={tw`flex-1`} onPress={()=>setModalVisible(false)}></Pressable>)}
                
            </View>
        </Modal>
  )
}

export default ColaboradorModalDetalle

