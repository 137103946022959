import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import tw from "twrnc";
import { Colores } from "../../constants/colores";
import usePermisos from "../../hooks/usePermisos";
import { useQuery } from "react-query";
import {
  aceptarDevolucionItem,
  rechazarDevolucionItem,
} from "../../services/apiClient";

const ConfirmarDevolucionItem = ({ item }) => {
  const { validarPermiso } = usePermisos();
  if (
    !validarPermiso([
      "administrar.todo",
      "administrar.items",
      "items.asignar",
      "items.crear",
    ])
  )
    return null;
  const requiereConformidadDevolucion = item?.requiereConformidadDevolucion
    ? true
    : false;
  const itemAceptado = item?.conformidadRecepcion ? true : false;
  if (!requiereConformidadDevolucion || !itemAceptado) return null;

  const [resultadoModal, setResultadoModal] = useState(false);
  const {
    data: respuestaAceptarDevolucionItem,
    isLoading,
    refetch: aceptar,
  } = useQuery("aceptarItem", () => aceptarDevolucionItem(item?.id), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: respuestaRechazarDevolucionItem,
    isLoading: isLoadingRechazar,
    refetch: rechazar,
  } = useQuery("rechazarItem", () => rechazarDevolucionItem(item?.id), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (respuestaAceptarDevolucionItem?.data) {
      setResultadoModal(respuestaAceptarDevolucionItem.data?.message);
    }
  }, [respuestaAceptarDevolucionItem]);

  useEffect(() => {
    if (respuestaRechazarDevolucionItem?.data) {
      setResultadoModal(respuestaRechazarDevolucionItem.data?.message);
    }
  }, [respuestaRechazarDevolucionItem]);

  if (isLoading || isLoadingRechazar) {
    return <Loader />;
  }

  if (resultadoModal)
    return (
      <View
        style={tw`mt-5 w-1/2 bg-blue-200 p-4 rounded-lg border border-blue-500 self-center`}
      >
        <Text style={tw`font-semibold text-center`}>{resultadoModal}</Text>
      </View>
    );

  return (
    <View
      style={tw`my-2 px-2 py-4 bg-yellow-100 rounded-md shadow-md flex flex-row items-center justify-center gap-2`}
    >
      <AntDesign name="warning" size={24} style={tw`text-yellow-700`} />
      <Text>
        Este activo requiere tu confirmacion de devolución, porfavor presiona el
        boton para confirmar
      </Text>
      <TouchableOpacity
        onPress={() => aceptar()}
        style={[
          tw`p-2 rounded-md shadow-md`,
          { backgroundColor: Colores.primary },
        ]}
      >
        <Text style={tw`text-white font-semibold`}>Confirmar</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => rechazar()}
        style={tw`bg-yellow-600 p-2 rounded-md shadow-md`}
      >
        <Text style={tw`text-white font-semibold`}>Rechazar</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ConfirmarDevolucionItem;