import React from "react";
import MaskedInput from "react-input-mask";
import { TextInputMask } from "react-native-masked-text";

import { esWeb } from "../utils/plataforma";

const CustomMaskedInput = ( props ) => {
  const { value, onChange, mask, child,  ...restProps } = props;

  /*
  if (esWeb()) {
    return (
      <MaskedInput
        mask={mask}
        maskChar=""
        value={value}
        onChange={onChange}
        {...restProps}
      >
        {child}
      </MaskedInput>
    );
  }
  */

  return (
    <TextInputMask
      type={"custom"}
      options={{
        mask: mask,
      }}
      value={value}
      onChangeText={onChange}
      {...restProps}
    >
      {child}
    </TextInputMask>
  );
};

export default CustomMaskedInput;