import React, { useState } from "react";
import { FlatList, Image, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from 'twrnc';
import {
  eliminarSugerencia,
  getEmpresas,
  getInformacionDashboard,
  getSolicitudesPlanDeCarrera,
} from "../../services/apiClient";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import ResultadoModal from "../../components/ResultadoModal";
import { Colores } from "../../constants/colores";
import { useNavigation } from "@react-navigation/native";
import { PageContainer } from "../../components/PageContainer";
import {Picker} from '@react-native-picker/picker';
import { FontAwesome } from '@expo/vector-icons';

import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from "expo-linear-gradient";
import { BarChart, ProgressChart } from "react-native-chart-kit";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { FontAwesome5 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
//import ColaboradoresContador from "../../components/Dashboard/ColaboradoresContador";
//import DepartamentosContador from "../../components/Dashboard/DepartamentosContador";
//import RecibosDeSueldo from "../../components/Dashboard/RecibosDeSueldo";


function DashboardScreen() {
  const navigator = useNavigation();
  const informacionLicencias = useQuery("informacionLicencias",getInformacionDashboard);
  const empresas = useQuery("empresas",getEmpresas);
  const [mostrarEmpresasEnDepartamento, setMostrarEmpresasEnDepartamento] = useState(false)
  const [mostrarDetalleDepartamentos, setMostrarDetalleDepartamentos] = useState(false)
  const {width} = useWindowDimensions()

  const data = {
    labels: ["Inactivo", "Activo"], // optional
    data: [0.4, 0.6],
  };

  const chartConfig = {
    backgroundGradientFrom: "#FFF",
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: "#FFF",
    backgroundGradientToOpacity: 0.5,
    color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false // optional
  };

  const barData = {
    labels: ["January", "February", "March"],
    datasets: [
      {
        data: [20, 45, 28]
      }
    ]
  };

  return (
    <PageContainer paddingSm>
        <View style={[tw`bg-white px-5`,{borderRadius:20}]}>

        <View style={tw`my-5`}>           
                <Text style={tw`text-lg font-semibold text-gray-500 mb-1 ml-1`}>Dashboard</Text>
        </View>


        {/* RECIBO Y COLABORADORES */}

        <View>

            <View style={tw`flex-row`}>

                {/* RECIBOS DE SUELDO */}

                <LinearGradient style={tw`flex-3 rounded-2xl p-3`} colors={['rgba(92, 91, 229, 0.26)','rgba(92, 91, 229, 0.98)']}>
                    <RecibosDeSueldo />
                </LinearGradient>


                {/* COLABORADORES */}

                <View style={tw`flex-9 ml-5 border border-gray-200 shadow-md rounded-2xl p-3`}>
                    <ColaboradoresContador />
                </View>
            </View>

            <View style={tw`flex-row mt-7`}>

                {/* LICENCIAS */}

                {/* <View style={tw`flex-3 rounded-2xl p-3 border border-gray-200 shadow-md`}>
                    <LicenciasContador />
                </View> */}

                {/* INFO POR COLABORADOR */}

                <View style={tw`flex-9 ml-5 border border-gray-200 shadow-md rounded-2xl p-3`}>
                    <InformacionPorColaborador />
                </View>

            </View>

            <View style={tw`flex-row mt-7`}>

                {/* DEPARTAMENTOS */}

                <View style={tw`flex-3 rounded-2xl p-3 border border-gray-200 shadow-md`}>
                    <DepartamentosContador />
                </View>


                <View style={tw`flex-9 pl-5`}>
                    <View style={tw`border py-3 border-gray-300 rounded-xl shadow-md`}>
                      <View style={tw`flex-row`}>
                        <View style={tw`flex-3`}>
                          <View style={tw`flex-row justify-center`}>
                            <FontAwesome5 name="gift" size={24} color={Colores.primaryStrong} />
                            <Text style={[tw`font-bold text-lg ml-2`,{color:Colores.primaryStrong}]}>Cumpleaños</Text>
                          </View>
                        </View>
                        <View style={tw`flex-6 flex-row`}>
                            <View style={tw`justify-center`}>
                              <MaterialIcons name="work" size={24} color='#6b7280' />
                            </View>
                            <View style={tw`justify-center`}>
                            <Text style={tw`font-semibold text-lg text-gray-500 ml-2`}>Ingresos del mes</Text>
                            </View>
                          </View>
                      </View>
                      <View style={tw`flex-row my-3`}>
                        <View style={tw`flex-3 justify-center items-center`}>
                          <Text style={{color:Colores.primaryStrong}}>Ayer</Text>
                          <Text style={{color:Colores.primary}}>23/11/2022</Text>
                        </View>
                        <View style={tw`flex-6 flex-row`}>
                          <View style={tw`justify-center`}>
                            <Image source={require('../../assets/avatar-prueba.png')} style={tw`w-7 h-7 mr-2`} />
                          </View>

                          <View style={tw`justify-center ml-3`}>
                            <Text>Matias Nicolas Castillo</Text>
                            <Text>Nubicom SRL</Text>
                          </View>
                        </View>
                      </View>
                      <View style={tw`flex-row my-3`}>
                        <View style={tw`flex-3 justify-center items-center`}>
                          <Text style={{color:Colores.primaryStrong}}>Hoy</Text>
                          <Text style={{color:Colores.primary}}>24/11/2022</Text>
                        </View>
                        <View style={tw`flex-6 flex-row`}>
                          <View style={tw`justify-center`}>
                            <Image source={require('../../assets/avatar-2.png')} style={tw`w-7 h-7 mr-2`} />
                          </View>

                          <View style={tw`justify-center ml-3`}>
                            <Text>Maria de los Angeles</Text>
                            <Text>Nubicom SRL</Text>
                          </View>
                        </View>
                      </View>
                      <View style={tw`flex-row my-3`}>
                        <View style={tw`flex-3 justify-center items-center`}>
                          <Text style={{color:Colores.primaryStrong}}>Hoy</Text>
                          <Text style={{color:Colores.primary}}>24/11/2022</Text>
                        </View>
                        <View style={tw`flex-6 flex-row`}>
                          <View style={tw`justify-center`}>
                            <Image source={require('../../assets/avatar-3.png')} style={tw`w-7 h-7 mr-2`} />
                          </View>

                          <View style={tw`justify-center ml-3`}>
                            <Text>Gille Cardozo</Text>
                            <Text>Nubicom SRL</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                </View>
            </View>
            <View style={tw`flex-row mt-7`}>
                {/* PUESTOs */}

                <View style={tw`flex-3 rounded-2xl p-3 border border-gray-200 shadow-md`}>
                    <PuestosContador />
                    
                </View>


                <View style={tw`flex-9 ml-5`}>

                </View>
            </View>
        </View>

            
        </View>

    </PageContainer>
  );
}

export default DashboardScreen;
