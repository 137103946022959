import { useNavigation } from "@react-navigation/native";
import { useAtom } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Modal,
  StyleSheet,
  Text,
  Pressable,
  View,
  Image,
  useWindowDimensions,
  FlatList,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from "twrnc";
import {
  avisoComentarios,
  nuevoComentarioAviso,
} from "../../services/apiClient";
import { userAtom } from "../../store/store";
import CarouselSwiper from "../carousel/CarouselSwiper";
import { MeGustaButton } from "./MeGustaButton";
import ReactPlayer from "react-player";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { FontAwesome5 } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import ColaboradorModalDetalle from "../Colaboradores/ColaboradorModalDetalle";

const AvisoDetalleModal = ({
  modalVisible,
  setModalVisible,
  aviso,
  comentarios,
}) => {
  const navigator = useNavigation();
  const { width } = useWindowDimensions();
  const [comentario, setComentario] = useState();
  const [verMas, setVerMas] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [contadorImagenes, setContadorImagenes] = useState(0);
  const [modalDetalleColaborador, setModalDetalleColaborador] = useState(false);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState();
  const queryClient = useQueryClient();

  const nuevoComentarioAvisoMutate = useMutation(nuevoComentarioAviso, {
    onSuccess: async (response) => {
      setComentario("");
      queryClient.invalidateQueries("avisos");
      queryClient.invalidateQueries("avisosCompletos");
    },
    onError: async (error) => {},
    onSettled: () => {
      refetch();
    },
  });

  const nuevoComentario = {
    aviso_id: aviso.id,
    comentario: comentario,
  };

  const Item = ({ item }) => {
    let contador = item.index + 1;

    return (
      <View
        style={[
          tw`justify-center`,
          { display: item.index === contadorImagenes ? null : "none" },
        ]}
      >
        <Image
          source={{ uri: item.item.foto }}
          style={{ width: "100%", height: 500 }}
          resizeMode="contain"
        />
        {aviso?.fotos?.length > 1 ? (
          <>
            {item.index >= 1 ? (
              <TouchableOpacity
                style={tw`left-5 absolute z-50`}
                onPress={() => setContadorImagenes(contadorImagenes - 1)}
              >
                <Ionicons
                  name="ios-arrow-back-circle-sharp"
                  size={35}
                  color="#FFF"
                />
              </TouchableOpacity>
            ) : null}
            {aviso?.fotos?.length !== contador ? (
              <TouchableOpacity
                style={tw`right-5 absolute z-50`}
                onPress={() => setContadorImagenes(contadorImagenes + 1)}
              >
                <Ionicons
                  name="ios-arrow-forward-circle-sharp"
                  size={35}
                  color="#FFF"
                />
              </TouchableOpacity>
            ) : null}
          </>
        ) : null}
      </View>
    );
  };

  const enviarComentario = () =>
    nuevoComentarioAvisoMutate.mutate(nuevoComentario);

  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <TouchableOpacity
          style={[
            aviso?.fotos.length > 0
              ? tw`flex-1 justify-center`
              : tw`flex-1 justify-center flex-row py-20`,
            { backgroundColor: "#000000bf" },
          ]}
          activeOpacity={1}
          onPressOut={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <TouchableOpacity
            style={tw`absolute top-5 right-5`}
            onPress={() => setModalVisible(false)}
          >
            <AntDesign name="close" size={24} color="#FFF" />
          </TouchableOpacity>

          <TouchableWithoutFeedback style={tw`justify-center`}>
            <View style={tw`w-8/12 mx-auto`}>
              {aviso?.fotos.length > 0 ? (
                <View style={tw`flex-1`}></View>
              ) : null}
              <View
                style={[
                  aviso?.fotos.length < 0
                    ? tw`bg-white flex-row flex-1`
                    : tw`bg-white flex-row`,
                  { borderRadius: 20, overflow: "hidden" },
                ]}
              >
                {aviso?.fotos.length > 0 ? (
                  <View
                    style={[
                      tw`flex-1 h-full`,
                      {
                        backgroundColor: "#000",
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                      },
                    ]}
                  >
                    <FlatList
                      data={aviso?.fotos}
                      keyExtractor={(item) => item.id}
                      renderItem={(item, index) => (
                        <Item item={item} index={index} />
                      )}
                    />
                  </View>
                ) : aviso?.url ? (
                  <View>
                    <ReactPlayer url={aviso.url} width="auto" />
                  </View>
                ) : null}

                <View
                  style={[
                    aviso?.fotos?.length <= 0 ? tw`flex-1 w-200` : tw`flex-1`,
                    {
                      borderBottomRightRadius: 20,
                      borderBottomLeftRadius:
                        aviso?.fotos?.length <= 0 ? 20 : 0,
                    },
                  ]}
                >
                  <View style={tw`flex-row p-3`}>
                    <View style={tw`justify-center`}>
                      <TouchableOpacity
                        onPress={() => {
                          setColaboradorSeleccionado(aviso.autor),
                            setModalDetalleColaborador(true);
                        }}
                      >
                        <Image
                          source={{ uri: aviso.autor.foto }}
                          style={tw`h-10 w-10 rounded-full`}
                        />
                      </TouchableOpacity>
                    </View>
                    <View style={tw`justify-center`}>
                      <TouchableOpacity
                        onPress={() => {
                          setColaboradorSeleccionado(aviso.autor),
                            setModalDetalleColaborador(true);
                        }}
                      >
                        <Text
                          style={tw`ml-3 font-semibold capitalize`}
                        >{`${aviso?.autor?.nombre} ${aviso?.autor?.apellido} - ${aviso?.autor?.puesto_actual[0]?.nombre}`}</Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={tw`flex-1 items-end justify-center pr-2 z-50`}
                    ></View>
                  </View>

                  <View
                    style={[tw`border-gray-300`, { borderBottomWidth: 1 }]}
                  />

                  <View style={tw`p-3`}>
                    <Text style={tw`text-lg font-semibold`}>
                      {aviso?.titulo}
                    </Text>
                    <Text style={tw`mt-2`}>{aviso?.texto}</Text>

                    <Text style={tw`font-semibold mt-2`}>
                      {moment(aviso?.created_at)
                        .fromNow()
                        .charAt(0)
                        .toUpperCase() +
                        moment(aviso?.created_at).fromNow().slice(1)}
                    </Text>
                  </View>


                  <View
                    style={[tw`mt-2 border-gray-300`, { borderBottomWidth: 1 }]}
                  />

                  <ScrollView style={tw`max-h-64`}>
                    <View>
                      {aviso?.comentariosActivo === 0 ? (
                        <Text style={tw`text-center text-gray-600 mt-10`}>
                          Los comentarios estan desactivados
                        </Text>
                      ) : null}

                      <View style={tw`px-4 pb-20`}>
                        <FlatList
                          data={aviso?.comentarios}
                          keyExtractor={(item) => item.id}
                          renderItem={({ item }) => (
                            <View style={tw`mt-4`}>
                              <View style={tw`flex-3 flex-row`}>
                                <TouchableOpacity
                                  style={tw`justify-center`}
                                  onPress={() => {
                                    setColaboradorSeleccionado(
                                      item.colaborador
                                    ),
                                      setModalDetalleColaborador(true);
                                  }}
                                >
                                  <Image
                                    source={{ uri: item.colaborador.foto }}
                                    style={tw`h-6 w-6 rounded-full`}
                                  />
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={tw`justify-center`}
                                  onPress={() => {
                                    setColaboradorSeleccionado(
                                      item.colaborador
                                    ),
                                      setModalDetalleColaborador(true);
                                  }}
                                >
                                  <Text
                                    style={tw`ml-2 font-bold capitalize`}
                                  >{`${item.colaborador.nombre} ${item.colaborador.apellido}`}</Text>
                                </TouchableOpacity>
                              </View>

                              <Text style={tw`mt-1 ml-4`}>
                                {item.comentario}{" "}
                                <Text
                                  style={tw`mt-1 text-xs font-normal text-stone-300`}
                                >
                                  {" "}
                                  {moment(item.created_at)
                                    .fromNow()
                                    .charAt(0)
                                    .toUpperCase() +
                                    moment(item.created_at).fromNow().slice(1)}
                                </Text>{" "}
                              </Text>
                            </View>
                          )}
                        />
                      </View>
                    </View>
                  </ScrollView>

                  {aviso?.comentariosActivo === 1 ? (
                    <View
                      style={[
                        tw`absolute bottom-0 w-full bg-white`,
                        {
                          borderBottomRightRadius: 20,
                          borderBottomLeftRadius:
                            aviso?.fotos?.length <= 0 ? 20 : 0,
                        },
                      ]}
                    >
                      <View
                        style={[tw`border-gray-300`, { borderBottomWidth: 1 }]}
                      />
                      <View
                        style={tw`flex-row justify-center items-center px-3 py-3`}
                      >
                        <View style={tw`flex-none m-2`}>
                          <Image
                            source={{ uri: user?.foto }}
                            style={tw`h-6 w-6 rounded-full`}
                          />
                        </View>
                        <View style={tw`grow`}>
                          <TextInput
                            value={comentario}
                            onChangeText={(e) => setComentario(e)}
                            placeholder="Agregar un comentario"
                            placeholderTextColor={Colores.placeHolder}
                            style={{ outlineStyle: "none" }}
                          />
                        </View>
                        <TouchableOpacity
                          style={tw`flex-none m-2`}
                          onPress={() => enviarComentario()}
                        >
                          <Text
                            style={[
                              tw`font-medium`,
                              { color: Colores.primaryMedium },
                            ]}
                          >
                            Publicar
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ) : null}
                </View>
              </View>
              {aviso?.fotos.length > 0 ? (
                <View style={tw`flex-1`}></View>
              ) : null}
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>

        <ColaboradorModalDetalle
          inicio
          modalVisible={modalDetalleColaborador}
          setModalVisible={setModalDetalleColaborador}
          colaboradorSeleccionado={colaboradorSeleccionado}
        />
      </Modal>
    </View>
  );
};

export default AvisoDetalleModal;
