import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
  TextInput,
  Pressable,
} from "react-native";
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AwesomeAlert from "react-native-awesome-alerts";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from "twrnc";

import {
  adminReseteaPassword,
  eliminarColaborador,
  getColaboradores,
} from "../../services/apiClient";

import ColaboradorAvatar from "../../components/Colaboradores/ColaboradorAvatar";
import { Loader } from "../../components/Loader";
import { awesomeAlertAtom, userAtom } from "../../store/store";
import ResultadoModal from "../../components/ResultadoModal";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { AntDesign } from "@expo/vector-icons";

function AdministrarColaboradoresScreen(props) {
  const colaboradoresQuery = useQuery("colaboradores", getColaboradores);
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filtro, setFiltro] = useState();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const resetearPasswordMutate = useMutation(adminReseteaPassword, {
    onSuccess: (response) => {
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const [user, _] = useAtom(userAtom);
  const checkPermisos = (listaSlugPermisos) => {
    const tienePermiso = user.permisos.some((permiso) =>
      listaSlugPermisos.includes(permiso.slug)
    );

    return tienePermiso;
  };

  let colaboradores = [];
  if (colaboradoresQuery.data && colaboradoresQuery.data.data?.ok) {
    colaboradores = colaboradoresQuery.data.data.colaboradores;
    colaboradores.map((colaborador) => {
      if (colaborador.empresa_actual.length) {
        colaborador.empresa_actual = colaborador?.empresa_actual[0];
      }
      if (colaborador.departamento_actual.length) {
        colaborador.departamento_actual = colaborador?.departamento_actual[0];
      }
      if (colaborador.puesto_actual.length) {
        colaborador.puesto_actual = colaborador?.puesto_actual[0];
      }
      return colaborador;
    });
  }

  const queryClient = useQueryClient();
  const eliminarMutate = useMutation(eliminarColaborador, {
    onSuccess: async (response) => {
      console.log("colaborador eliminado", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("colaboradores");
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar eliminar",
        error.response.data.message
      );
      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error.response.data.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
    onSettled: (data) => {
      if (data.data.ok) {
        setmostrarAlerta(false);
      }
    },
  });

  const getColaborador = (colaboradorId) => {
    const colaborador = colaboradores.filter(
      (colaborador) => colaborador.id === colaboradorId
    );
    if (colaborador.length) {
      console.log(colaborador);
      //duevele el primer colaborador que se econtro con ese id
      return colaborador[0];
    }
    console.log("No se encontro el colaborador para el id ", colaboradorId);
    return;
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = colaboradores.filter((item) => {
        const itemData = item.nombre
          ? `${item.nombre.toUpperCase()} ${item.apellido.toUpperCase()}`
          : "".toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredData(newData);
    } else {
      setFilteredData(colaboradores);
    }
  };

  if (colaboradoresQuery.isLoading)
    return <Loader text="Cargando Colaboradores..." />;

  const confirmar = (colaboradorId) => {
    setawesomeAlert({
      show: true,
      showProgress: resetearPasswordMutate.isLoading,
      title: "Atención",
      message: "Esta seguro que desea resetear la contraseña?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Resetear",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        resetearPasswordMutate.mutate(colaboradorId);
      },
      onDismiss: () => {
        resetAwesomeAlert();
      },
      onCancelPressed: () => {
        resetAwesomeAlert();
      },
    });
  };

  return (
    <PageContainer paddingMd>
      <View style={[tw`p-5 bg-white`, { borderRadius: 20 }]}>
        <View style={tw`z-10`}>
          <AwesomeAlert
            show={awesomeAlert.show}
            showProgress={false}
            title={awesomeAlert.title}
            message={awesomeAlert.message}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={awesomeAlert.showCancelButton}
            showConfirmButton={awesomeAlert.showConfirmButton}
            confirmText={awesomeAlert.confirmText}
            customView={awesomeAlert.customView}
            confirmButtonColor={awesomeAlert.confirmButtonColor}
            onDismiss={awesomeAlert.onDismiss}
            onCancelPressed={awesomeAlert.onCancelPressed}
            onConfirmPressed={awesomeAlert.onConfirmPressed}
          />
        </View>
        <View style={tw`mb-3`}>
          <View style={tw`flex-1 justify-center`}>
            <Text style={tw`text-lg font-semibold text-gray-500`}>
              Administrar Colaboradores
            </Text>
          </View>
          <View style={tw`flex flex-row items-center justify-between `}>
            <View
              style={tw`flex gap-2 items-start justify-start bg-white rounded-md p-2 mx-auto mt-5  border border-gray-200 rounded-[10px]`}
            >
              <Text>Referencia:</Text>
              <View style={tw`flex flex-row items-center justify-center gap-2`}>
                <View style={tw`w-[10px] h-[10px] bg-amber-200`}></View>
                <Text>De Licencia</Text>
              </View>
              <View style={tw`flex flex-row items-center justify-center gap-2`}>
                <View style={tw`w-[10px] h-[10px] bg-gray-200`}></View>
                <Text>Inactivo</Text>
              </View>
            </View>
            <View style={tw`flex-1 items-end justify-center`}>
              <TouchableOpacity
                onPress={() =>
                  props.navigation.navigate("Colaboradores Eliminados")
                }
                style={[
                  tw`border border-gray-200 flex-row py-3`,
                  { borderRadius: 10 },
                ]}
              >
                <View
                  style={[
                    tw`justify-center pl-7 pr-5 py-1`,
                    { borderLeftWidth: 2, borderColor: Colores.primary },
                  ]}
                >
                  <Text style={tw`text-gray-700 text-center`}>
                    Ver Desvinculados
                  </Text>
                </View>
                <View style={tw`justify-center`}>
                  <MaterialIcons
                    name="delete"
                    size={20}
                    color="gray"
                    style={tw`mr-5`}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <View style={tw`flex-1 items-end justify-center`}>
              <TouchableOpacity
                onPress={() => props.navigation.navigate("Crear Colaborador")}
                style={[
                  tw`border border-gray-200 flex-row py-3`,
                  { borderRadius: 10 },
                ]}
              >
                <View
                  style={[
                    tw`justify-center pl-7 pr-5 py-1`,
                    { borderLeftWidth: 2, borderColor: Colores.primary },
                  ]}
                >
                  <Text style={tw`text-gray-700 text-center`}>
                    Nuevo Colaborador
                  </Text>
                </View>
                <View style={tw`justify-center`}>
                  <FontAwesome5
                    name="user-plus"
                    size={20}
                    color="gray"
                    style={tw`mr-5`}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={tw`justify-center`}>
          <TextInput
            style={[
              tw` w-full px-3 py-2 text-gray-700 bg-white border border-solid border-gray-300`,
              { borderRadius: 23 },
            ]}
            placeholder="Buscar"
            onChangeText={(text) => {
              searchFilterFunction(text);
              setFiltro(text);
            }}
          />
          <AntDesign
            name="search1"
            size={20}
            color={"#7D7D7D"}
            style={tw`absolute right-4 top-1.5`}
          />
        </View>

        <FlatList
          data={filtro ? filteredData : colaboradores}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <View
              style={[
                tw`p-3 mt-2 border border-gray-300 flex-row shadow-md`,
                item.licencia_activa?.length
                  ? tw`bg-amber-200`
                  : tw`bg-white-400`,
                item.activo !== 1 ? tw`bg-gray-200 opacity-50` : "",
                { borderRadius: 16 },
              ]}
            >
              <View style={tw`items-center justify-center`}>
                <Pressable
                  onPress={() => {
                    props.navigation.navigate("Editar Colaborador", {
                      colaborador: item,
                    });
                  }}
                >
                  <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
                </Pressable>
              </View>
              <View style={tw`ml-2 flex-1`}>
                <Pressable
                  onPress={() => {
                    props.navigation.navigate("Editar Colaborador", {
                      colaborador: item,
                    });
                  }}
                >
                  <Text
                    style={tw`text-lg capitalize`}
                  >{`${item.nombre} ${item.apellido}`}</Text>
                  <Text style={tw`mt-1`}>
                    <FontAwesome5
                      name="building"
                      size={10}
                      color={Colores.primary}
                      style={tw`mr-1`}
                    />
                    {item.puesto_actual.nombre
                      ? item.puesto_actual.nombre
                      : "-"}
                  </Text>
                  <Text style={tw`mt-1`}>
                    <FontAwesome5
                      name="envelope"
                      size={10}
                      color={Colores.primary}
                      style={tw`mr-1`}
                    />
                    {item.empresa_actual.nombre
                      ? item.empresa_actual.nombre
                      : "-"}
                  </Text>
                  <Text style={tw`mt-1`}>
                    <FontAwesome5
                      name="file"
                      size={10}
                      color={Colores.primary}
                      style={tw`mr-1`}
                    />
                    {`Legajo: ${item.numeroLegajo ? item.numeroLegajo : "-"}`}
                  </Text>
                  {item.licencia_activa?.length ? (
                    <Text
                      style={tw`w-1/4 bg-amber-400 text-amber-800 text-xs font-semibold p-1 shadow-md mt-2 rounded-sm text-center`}
                    >
                      Fin de Licencia{" "}
                      {item.licencia_activa[0].extension
                        ? item.licencia_activa[0].extension
                        : new Date(
                            item.licencia_activa[0].fin
                          ).toLocaleDateString()}
                    </Text>
                  ) : null}
                </Pressable>
              </View>

              <View
                style={[tw`flex flex-col items-start p-1 justify-end gap-2`]}
              >
                <TouchableOpacity
                  style={[
                    tw`p-2 justify-center items-center text-cyan-600 rounded-sm`,
                    { backgroundColor: Colores.primary },
                  ]}
                  onPress={() => {
                    props.navigation.navigate("Solicitar Licencia", {
                      colaborador: item,
                    });
                  }}
                >
                  <Text style={[tw`text-white`]}>Asignar Licencia</Text>
                </TouchableOpacity>
                {checkPermisos([
                  "administrar.todo",
                  "administrar.colaboradores",
                  "editar.colaborador",
                ]) ? (
                  <TouchableOpacity
                    style={[
                      tw`p-2 justify-center items-center text-cyan-600 rounded-sm`,
                      { backgroundColor: Colores.primary },
                    ]}
                    onPress={() => confirmar(item.id)}
                  >
                    <Text style={[tw`text-white`]}>Resetear Contraseña</Text>
                  </TouchableOpacity>
                ) : null}
              </View>

              <TouchableOpacity
                style={[
                  tw`p-2 justify-center items-center text-cyan-600 absolute top-1 right-1 rounded-full`,
                  { backgroundColor: Colores.primary },
                ]}
                onPress={() => {
                  props.navigation.navigate("Editar Colaborador", {
                    colaborador: item,
                  });
                }}
              >
                <FontAwesome5 name="pen" size={12} color={"#FFF"} />
              </TouchableOpacity>
            </View>
          )}
        />
      </View>
    </PageContainer>
  );
}

export default AdministrarColaboradoresScreen;
