import { View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import tw from "twrnc";
import { Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { useResetAtom } from "jotai/utils";
import { awesomeAlertAtom } from "../../store/store";
import { useMutation, useQueryClient } from "react-query";
import { eliminarItem } from "../../services/apiClient";
import { useAtom } from "jotai";
import ResultadoModal from "../ResultadoModal";

const AccionesDatatableItem = ({ item }) => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const [_, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const confirmar = () => {
    console.log("confirmar");
    setawesomeAlert({
      show: true,
      showProgress: eliminarMutate.isLoading,
      title: "Atención",
      message: "Esta seguro de desea eliminar el activo?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        eliminarMutate.mutate(item.id);
      },
      onDismiss: () => {
        resetAwesomeAlert();
      },
      onSettled: (data) => {
        if (data.data.ok) {
          resetAwesomeAlert();
        }
      },
    });
  };

  const eliminarMutate = useMutation(eliminarItem, {
    onSuccess: async (response) => {
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("items");
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }

      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });
  return (
    <View style={tw`flex flex-row items-center justify-center`}>
      <Button onPress={() => navigation.navigate("Detalle Item", { item })}>
        <Ionicons name="open-outline" size={20} color="gray" />
      </Button>
      <Button onPress={() => navigation.navigate("Asignar Items", { item })}>
        <Ionicons name="pencil" size={20} color="gray" />
      </Button>
      <Button onPress={() => confirmar()}>
        <Ionicons name="trash-outline" size={20} color="gray" />
      </Button>
    </View>
  );
};

export default AccionesDatatableItem;