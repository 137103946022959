import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  FlatList,
  StyleSheet,
  Button,
  ScrollView,
  Modal,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import { useQuery } from "react-query";

import tw from 'twrnc';
import { PageContainer } from "../../components/PageContainer";
import { getRanking, pronosticar } from "../../services/apiClient";


export default function RankingScreen(props) {
  const {width} = useWindowDimensions()

  const ranking = useQuery("ranking",getRanking);

  console.log(ranking?.data?.data?.ranking)

  return (
    <PageContainer paddingMd>
      <ScrollView>
        {width >= 758 && (
          <LinearGradient style={[tw`flex-row flex-1`,{borderRadius:10}]} colors={['#af032e','#9d022b']}>
              <View style={tw`flex-4 justify-center`}>
                <Text style={tw`text-white text-2xl font-semibold text-center`}>¡Jugá al PRODE 2022!</Text>
              </View>
              <View style={tw`flex-4 justify-center`}>
                <Image source={require('../../assets/Prode/img.png')} style={[tw`h-30 w-full`,{resizeMode:'cover', borderRadius:10}]} />
              </View>
          </LinearGradient>
        )}
        <View style={[ width >= 768 ? tw`bg-white mt-7 p-5`: tw`bg-white`,{borderRadius:width >=758 && 20}]}>
          {width < 758 && (
            <View style={tw`bg-red-500`}>
              <Image source={require('../../assets/Prode/img.png')} style={[tw`h-48 w-full`,{resizeMode:'cover'}]} />
            </View>
          )}
        <View style={[tw`my-5 px-3 justify-center`,{backgroundColor:'#FFF'}]}>
          <Text style={tw`text-lg text-gray-700 font-semibold`}>RANKING DE PUNTOS </Text>
        </View>
        <LinearGradient style={tw`px-5 py-1`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
          <Text style={tw`text-lg text-white`}>GENERAL </Text>
        </LinearGradient>

        <FlatList
        data={ranking?.data?.data?.ranking}
        keyExtractor={item => item.id}
        renderItem={({item, index})=>(
        <View style={tw`w-full`}>
            <View style={tw`flex-row`}>
                <View style={tw`flex-1 py-2 pl-3 justify-center`}>
                    <Image source={{uri:item.foto}} style={tw`h-10 w-10 rounded-full`} />
                </View>
                <View style={tw`flex-7 justify-center pl-3`}>
                    <Text>{`${item?.nombre} ${item?.apellido}`}</Text>
                </View>
                <View style={tw`flex-2 justify-center pl-3`}>
                    <Text>{item.puntos}</Text>
                </View>
            </View>
        </View>
        )}
        />
        </View>
      </ScrollView>

    </PageContainer>
  );
}

