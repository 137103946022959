import { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  ActivityIndicator,
  Image,
  Pressable,
  Switch,
  TouchableOpacity,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import tw from "twrnc";
import * as ImagePicker from "expo-image-picker";
import { Ionicons } from "@expo/vector-icons";
import { nuevoAviso } from "../services/apiClient";
import { MultiSelectEmpresas } from "../components/MultiSelect";
import { useAtom } from "jotai";
import { MaterialIcons } from "@expo/vector-icons";
import uuid from "react-native-uuid";

import { empresasSeleccionadasAtom, idsEmpresasAtom } from "../store/store";
import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { Colores } from "../constants/colores";
import AvisoModalVistaPrevia from "../components/Avisos/AvisoModalVistaPrevia";
import { FontAwesome5 } from "@expo/vector-icons";
import { esAndroid, esWeb } from "../utils/plataforma";
import { validateURL } from "../utils/generales";

export const AvisoForm = (props) => {
  const [foto, setfoto] = useState();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, setEmpresasSeleccionadas] = useAtom(empresasSeleccionadasAtom);
  const [idsEmpresas, setidsEmpresas] = useAtom(idsEmpresasAtom);
  const [esTemporal, setesTemporal] = useState(0);
  const [comentariosActivo, setcomentariosActivo] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [sinImagen, setSinImagen] = useState(false);
  const queryClient = useQueryClient();
  const { navigation } = props;

  const permisos = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      // handle permissions denied
      console.log("sin permisos");
    }
  };

  useEffect(() => {
    permisos();
  }, []);

  const pickImages = async () => {
    setIsLoading(true);
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: true,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 0.7,
    });

    if (!result.cancelled) {
      // Valida el tamaño del archivo
      const isSizeValid = await Promise.all(
        result.selected.map(async (image) => {
          try {
            let size;

            if (
              typeof image.uri === "string" &&
              image.uri.startsWith("data:")
            ) {
              // Web environment
              size = image.uri.length;
            } else {
              // Native environment
              const fileInfo = await FileSystem.getInfoAsync(image.uri);
              size = fileInfo.size;
            }

            const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
            return size <= maxSizeInBytes;
          } catch (error) {
            console.log(
              "Error al intentar obtener informacion del archivo",
              error
            );
            return false;
          }
        })
      );

      if (!isSizeValid.every((isValid) => isValid)) {
        alert("El tamaño maximo permitido por imagen es de 2 MB.");
        return;
      }
      // Set the selected images
      setImages(result.selected || [result]);

      // Load the images
      const loadedImages = await Promise.all(
        (result.selected || [result]).map(async (image) => {
          try {
            let imageUri = image.uri;
            if (esWeb()) {
              // obtiene la url de la imagen en la plataforma web
              const response = await fetch(imageUri);
              const blob = await response.blob();
              let type = imageUri.split(";")[0].split("/")[1];

              return {
                uri: response.url,
                file: blob,
                type: `image/${type}`,
                name: `${uuid.v4()}.${type}`,
              };
            }

            if (esAndroid()) {
              const extension = image.uri.split(".").pop();
              return {
                uri: imageUri,
                type: `image/${extension}`,
                name: `${uuid.v4()}.${extension}`,
              };
            }

            // lee y crea un nuevo objeto con la url y el archivo de la imagen
          } catch (error) {
            console.log("Error reading image file:", error);
          }
        })
      );

      // Set the loaded images
      setImages(loadedImages);
    }
  };

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
    url: "",
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  const toggleEsTemporalSwitch = () =>
    setesTemporal((previousState) => !previousState);

  const toggleComentariosActivoSwitch = () =>
    setcomentariosActivo((previousState) => !previousState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    //reset form
    reset({ titulo: "", texto: "", url: "" });
    setfoto();
    setesTemporal(0);
    setcomentariosActivo(0);
    setidsEmpresas();
    //resetear multiselect
    setEmpresasSeleccionadas([]);
    setImages([]);
  };

  const nuevoAvisoMutate = useMutation(nuevoAviso, {
    onSuccess: async (response) => {
      resetForm();
      queryClient.invalidateQueries("avisos");
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El aviso fue creado" />
        ),
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
      });
    },
  });

  const limpiarRedirigir = () => {
    navigation.navigate("Avisos");
    setImages([]);
    setSinImagen(false);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("unica", 1);
    if (images?.length > 0) {
      images.forEach((img) => {
        formData.append("foto[]", img?.file ? img.file : img);
      });
    }
    //console.log("foto", formData)
    if (idsEmpresas) {
      formData.append("empresas", idsEmpresas);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
    }

    nuevoAvisoMutate.mutate(formData);
    setSinImagen(false);
  };


  return (
    <>
      {/* HEADER PERSONALIZADO */}
      <View
        style={[
          tw`h-15 w-full justify-center`,
          {
            backgroundColor: Colores.primary,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          },
        ]}
      >
        <View style={tw`flex flex-row`}>
          <View style={tw`flex-2 justify-center`}>
            <TouchableOpacity onPress={() => limpiarRedirigir()}>
              <FontAwesome5
                name="times"
                size={24}
                color="white"
                style={tw`ml-7`}
              />
            </TouchableOpacity>
          </View>
          <View style={tw`flex-3 items-center justify-center`}>
            <Text style={tw`text-white text-lg`}>Nueva publicacion</Text>
          </View>
          <View style={tw`flex-2 items-center justify-center`}>
            {/* <TouchableOpacity>
              <Text style={tw`text-white text-lg`}>Siguiente</Text>
            </TouchableOpacity> */}
          </View>
        </View>
      </View>
      {/* FIN HEADER PERSONALIZADO */}

      <View style={tw`h-full w-full px-3`}>
        {images?.length > 0 ? (
          <AvisoModalVistaPrevia
            modalVisible={modalVisible}
            cambiarEstadoModal={setModalVisible}
            archivos={images}
            setArchivos={setImages}
          />
        ) : null}
        {images?.length > 0 || sinImagen ? (
          <View style={tw`mt-2`}>
            <View style={tw`flex flex-row`}>
              {!sinImagen && (
                <View style={tw`flex-1`}>
                  <Pressable onPress={() => setModalVisible(true)}>
                    <Image
                      source={{ uri: images[0].uri }}
                      style={[tw`h-20 w-20`, { borderRadius: 16 }]}
                    />
                  </Pressable>
                </View>
              )}
              <View
                style={
                  !sinImagen
                    ? tw`flex-4 justify-center pl-5`
                    : tw`flex-4 justify-center`
                }
              >
                <Controller
                  control={control}
                  rules={{
                    required: "Ingrese un titulo",
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      style={[
                        tw` w-full px-3 py-2.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                        { borderRadius: 50 },
                      ]}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      placeholder="Titulo"
                      value={value}
                    />
                  )}
                  name="titulo"
                />
                {errors?.titulo && (
                  <MensajeError message={errors.titulo?.message} />
                )}
              </View>
            </View>
            <Controller
              control={control}
              rules={{
                required: "Ingrese el texto",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full h-50 px-3 mt-2 py-1.5 text-base font-normal text-gray-700 border border-gray-300 shadow-sm`,
                    { borderRadius: 10 },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  placeholder="Descripción"
                  multiline={true}
                  value={value}
                />
              )}
              name="texto"
            />
            {errors?.texto && <MensajeError message={errors.texto?.message} />}
            <View>
              <Controller
                control={control}
                defaultValue={comentariosActivo}
                name="comentariosActivo"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={tw`flex-row flex my-5`}>
                    <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={(value) => {
                        toggleComentariosActivoSwitch;
                        onChange(value ? 1 : 0);
                      }}
                      value={value}
                      style={tw`mt-1`}
                    />
                    <Text style={tw`ml-3 text-gray-700 text-lg`}>
                      Activar comentarios
                    </Text>
                  </View>
                )}
              />
            </View>
            <View style={tw`hidden`}>
              <Controller
                control={control}
                defaultValue={esTemporal}
                name="esTemporal"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={tw`flex-row flex my-5`}>
                    <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={(value) => {
                        toggleEsTemporalSwitch;
                        onChange(value ? 1 : 0);
                      }}
                      value={value}
                      style={tw`mt-1`}
                    />
                    <Text style={tw`ml-3 text-gray-700 text-lg`}>
                      Subir como historia
                    </Text>
                  </View>
                )}
              />
            </View>
            <View>
              <Controller
                control={control}
                rules={{ validate: validateURL }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` w-full px-3 py-2.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                      { borderRadius: 50 },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    placeholder="Video url"
                    value={value}
                  />
                )}
                name="url"
              />
              {errors?.url && <MensajeError message={errors.url?.message} />}
            </View>
            <View style={tw`mt-3`}>
              <MultiSelectEmpresas />
            </View>
            {nuevoAvisoMutate.isLoading ? (
              <ActivityIndicator size="small" color={Colores.primary} />
            ) : (
              <View style={tw`items-center justify-center mb-10 flex-row`}>
                <TouchableOpacity
                  style={[
                    tw`mt-4 px-5 py-2.5 shadow-md mr-2`,
                    { backgroundColor: Colores.primary, borderRadius: 15 },
                  ]}
                  onPress={() => {
                    setImages([]), setSinImagen(false);
                  }}
                >
                  <Ionicons name="arrow-back" size={20} color="white" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    tw`mt-4 px-20 py-2.5 shadow-md`,
                    { backgroundColor: Colores.primary, borderRadius: 15 },
                  ]}
                  onPress={handleSubmit(onSubmit)}
                >
                  <Text style={tw`text-white text-center`}>Publicar</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        ) : (
          <>
            <Pressable onPress={pickImages} foreground={true} style={tw`mt-2`}>
              <View
                style={[
                  tw`items-center h-100 w-full justify-center rounded-lg`,
                  { backgroundColor: Colores.primaryLight },
                ]}
              >
                <Text style={{ color: Colores.primary }}>Agregar imagen</Text>
              </View>
            </Pressable>
            <View style={tw`items-center justify-center mt-3`}>
              <TouchableOpacity
                style={[
                  tw`items-center justify-center flex-row py-2 px-5`,
                  { backgroundColor: Colores.primary, borderRadius: 15 },
                ]}
                onPress={() => setSinImagen(true)}
              >
                <MaterialIcons
                  name="image-not-supported"
                  size={20}
                  style={tw`mr-1 mt-1 text-white`}
                />
                <Text style={tw`text-white`}> Publicar sin imagen</Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor="green"
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Avisos");
        }}
      />
    </>
  );
};
