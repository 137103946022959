import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc";

export default function FechaCaduca({ fecha }) {
  if (!fecha) return null;
  return (
    <View style={tw`flex flex-row items-center`}>
      <Text style={tw`flex-1`}>Fecha caducidad: </Text>
      <Text style={tw`text-gray-500`}>
        {new Date(fecha).toLocaleDateString("es-AR", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </Text>
    </View>
  );
}