import React, { useState } from "react";
import tw from "twrnc";
import EstadoItem from "./EstadoItem";
import { View, Text } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";

const ItemCard = ({ item, pendiente = false }) => {
  const navigation = useNavigation();
  const {
    titulo,
    fechaAsignacion,
    fechaCaduca,
    fechaDevolucion,
    cantidadEntregada,
    created_at,
  } = item;
  const [menu, setMenu] = useState(false);
  return (
    <View
      style={tw`md:max-w-1/3 bg-white flex flex-col gap-2 rounded-xl px-3 py-4 shadow-md ${
        pendiente ? "border border-sky-300 bg-sky-100" : ""
      }`}
    >
      <View style={tw`relative flex flex-row justify-between mb-4`}>
        <EstadoItem item={item} />
        <TouchableOpacity onPress={() => setMenu((prev) => !prev)}>
          <MaterialCommunityIcons
            name="dots-vertical"
            size={24}
            color="black"
          />
        </TouchableOpacity>
        <View
          style={tw`absolute top-6 right-2 bg-white rounded-xl shadow-md px-3 py-2 flex hover:cursor-pointer ${
            menu ? "visible" : "hidden"
          } z-100`}
        >
          <TouchableOpacity
            onPress={() => {
              setMenu((prev) => !prev);
              navigation.navigate("Detalle Mi Item", { item });
            }}
          >
            <Text>Detalle</Text>
          </TouchableOpacity>
        </View>
      </View>
      <Text style={tw`flex-1 text-lg font-semibold uppercase`}>{titulo}</Text>
      <View style={tw`flex flex-row items-center justify-between`}>
        <Text style={tw`flex-1`}>Fecha asignación: </Text>
        <Text style={tw`text-gray-500`}>
          {new Date(created_at).toLocaleDateString("es-AR", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Text>
      </View>
      {fechaCaduca ? (
        <View style={tw`flex flex-row items-center`}>
          <Text style={tw`flex-1`}>Fecha caducidad: </Text>
          <Text style={tw`text-gray-500`}>
            {new Date(fechaCaduca).toLocaleDateString("es-AR", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </Text>
        </View>
      ) : null}
      {fechaDevolucion ? (
        <View style={tw`flex flex-row items-center`}>
          <Text style={tw`flex-1`}>Fecha caducidad: </Text>
          <Text style={tw`text-gray-500`}>
            {new Date(fechaDevolucion).toLocaleDateString("es-AR", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </Text>
        </View>
      ) : null}

      <View style={tw`flex flex-row items-center`}>
        <Text style={tw`flex-1`}>Cantidad: </Text>
        <Text style={tw`text-gray-500`}>{cantidadEntregada}</Text>
      </View>
    </View>
  );
};

export default ItemCard;