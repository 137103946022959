import React from "react";
import { useAtom } from "jotai";
import { View, Text } from "react-native";
import { userAtom } from "../../store/store";
import tw from "twrnc";
import ColaboradorAvatar from "../Colaboradores/ColaboradorAvatar";

const ColaboradorItem = ({ colaborador, reversed = false }) => {
  let datos = null;
  if (colaborador) {
    datos = colaborador;
  } else {
    const [user] = useAtom(userAtom);
    datos = user;
  }
  const orientacion = reversed ? "flex-row-reverse" : "flex-row";

  const { nombre, apellido, foto, id } = datos;
  if (!datos) return <Text>Colaborador sin dato</Text>;
  return (
    <View style={tw`w-full flex ${orientacion} items-center gap-2`}>
      {datos ? <ColaboradorAvatar size={34} foto={foto} key={id} /> : null}
      <Text style={tw`capitalize`}>{`${apellido}, ${nombre}`}</Text>
    </View>
  );
};

export default ColaboradorItem;