import React, { useState } from "react";
import { useAtom } from "jotai";
import {
  Button,
  Image,
  Modal,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import tw from "twrnc";
import { MaterialIcons } from "@expo/vector-icons";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { FontAwesome5 } from "@expo/vector-icons";
import { StoryContainer } from "react-native-stories-view";

import { esVisibleHistoriaModalAtom } from "../../store/store";
import { LinearGradient } from "expo-linear-gradient";
import { useQuery } from "react-query";
import { getAvisosTemporales } from "../../services/apiClient";

function HistoriaModal({ visible, historia, navigation }) {
  const { width } = useWindowDimensions();
  const [historiaModalVisible, setHistoriaModalVisible] = useAtom(
    esVisibleHistoriaModalAtom
  );


  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        setHistoriaModalVisible(!historiaModalVisible);
      }}
    >
      <View
        style={[
          tw`flex-1 flex-row`,
          { backgroundColor: "rgba(21,21,21,0.79)" },
        ]}
      >
        {width >= 768 ? <View style={tw`flex-1`}></View> : null}

        <View style={tw`flex-1`}>
          <View
            style={[tw`flex-1`, { backgroundColor: "rgba(21,21,21,0.79)" }]}
          >
            {historia?.fotos?.length ? (
              <Image
                style={{ aspectRatio: 9/16, height: "100%", width: "100%" }}
                source={{ uri: historia.fotos[0].foto }}
              />
            ) : null}
          </View>
          {historia?.titulo !== "undefined" ? (
            <LinearGradient
              style={[tw`absolute bottom-0 h-50 w-full p-2`]}
              colors={[
                "rgba(0, 0, 0, 0)",
                "rgba(0, 0, 0, 0.5)",
                "rgba(0, 0, 0, 0.8)",
              ]}
            >
              <ScrollView>
                <Text style={tw`text-white mt-2`}>{historia?.titulo}</Text>
                <Text style={tw`text-white mt-2`}>{historia?.texto}</Text>
              </ScrollView>
            </LinearGradient>
          ) : null}
          <TouchableOpacity
            style={[
              tw`p-1 bg-black rounded-full right-5 top-5 absolute`,
              { backgroundColor: "#242424" },
            ]}
            onPress={() => setHistoriaModalVisible(false)}
          >
            <MaterialIcons name="close" size={20} color="white" />
          </TouchableOpacity>
        </View>
        {width >= 768 ? <View style={tw`flex-1`}></View> : null}
      </View>
    </Modal>
  );
}

export default HistoriaModal;
