import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Pressable,
  ScrollView,
  useWindowDimensions,
  FlatList,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import tw from 'twrnc';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

import AwesomeAlert from "react-native-awesome-alerts";
import { Colores } from "../../constants/colores";
import ResultadoModal from "../../components/ResultadoModal";
import { getColaboradoresPublica, nuevoBeneficio } from "../../services/apiClient";
import { PageContainer } from "../../components/PageContainer";
import ColaboradorAvatar from "../../components/Colaboradores/ColaboradorAvatar";
import {Picker} from '@react-native-picker/picker';

export const NuevaSancionScreen = (props) => {
  
  const {width} = useWindowDimensions();
  const [foto, setfoto] = useState()
  const colaboradoresQuery = useQuery("colaboradores", getColaboradoresPublica);
  const [filtro, setFiltro] = useState()
  const [filteredData, setFilteredData] = useState([])
  const [tipoSancion, setTipoSancion] = useState()
  const [tipoMotivo, setTipoMotivo] = useState()

  let colaboradores = [];
  if (colaboradoresQuery.data && colaboradoresQuery.data.data?.ok) {
    colaboradores = colaboradoresQuery.data.data.colaboradores;
    colaboradores.map((colaborador) => {
      if (colaborador.empresa_actual.length) {
        colaborador.empresa_actual = colaborador?.empresa_actual[0];
      }
      if (colaborador.departamento_actual.length) {
        colaborador.departamento_actual = colaborador?.departamento_actual[0];
      }
      if (colaborador.puesto_actual.length) {
        colaborador.puesto_actual = colaborador?.puesto_actual[0];
      }
      return colaborador;
    });
  }

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);
  const queryClient = useQueryClient();

  const tiposSanciones = [
    {
        "id": 1,
        "tipo": "Llamado de atención",
        "empresa_id": null,
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 2,
        "tipo": "Llamado de atención severo",
        "empresa_id": null,
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 3,
        "tipo": "Sanción",
        "empresa_id": null,
        "created_at": null,
        "updated_at": null
    }
]

const motivosSanciones= [
    {
        "id": 1,
        "motivo": "Ausentismo",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 2,
        "motivo": "Llegadas tardes",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 3,
        "motivo": "Conflictivo",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 4,
        "motivo": "Mal uso de los bienes de la empresa",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 5,
        "motivo": "Deshonestidad",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 6,
        "motivo": "Negligencia",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 7,
        "motivo": "Exceso de velocidad",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 8,
        "motivo": "Falta de uso de EPP",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 9,
        "motivo": "Falta de contracción al trabajo",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 10,
        "motivo": "Uso inadecuado de vehículos corporativos",
        "created_at": null,
        "updated_at": null
    }
]

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    //reset form
    reset({ titulo: "", texto: "" });
    setfoto();
    setactivo(0);
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    setfoto(result);
    console.log(result);
  };

  const nuevoBeneficioMutate = useMutation(nuevoBeneficio, {
    onSuccess: async (response) => {
      resetForm();
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El beneficio fue creado" />
        ),
      })
      queryClient.invalidateQueries("beneficios");

    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const formData = new FormData();

  const onSubmit = (data) => {
    formData.append("unica", 1);
    if (foto) {
      formData.append("foto", foto?.file);
    }


    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
    }
    nuevoBeneficioMutate.mutate(formData);
  };

  const searchFilterFunction = (text) =>{
    if(text){
        const newData = colaboradores.filter(item => {
          const itemData = item.nombre ? `${item.nombre.toUpperCase()} ${item.apellido.toUpperCase()}` : ''.toLocaleUpperCase();
          const textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;
        })
        setFilteredData(newData)
    }else{
      setFilteredData(colaboradores)
    }
  }


  return (
    <PageContainer paddingMd>
    <ScrollView style={tw`h-full w-full`}>
    <View style={width >= 768 ? tw`px-15 py-10 bg-white rounded-xl` : tw`px-4 bg-white`}>
      {width >= 768 && (
      <View style={[tw`w-full justify-center bg-white`,{borderTopLeftRadius:20, borderTopRightRadius:20}]}>
          <Text style={tw`text-lg font-semibold text-gray-600`}>Nueva Sancion</Text>
      </View>
      )}
        <View style={[ width >= 768 ? tw`p-5 bg-white h-100` : tw`p-2 bg-white`,{borderRadius: width >=768 ? 20 : 0 }]}>   

        <Text style={tw`mb-5 text-lg text-gray-900`} >Seleccione el colaborador</Text>

        <View style={tw``}>
        <TextInput 
            style={[tw`form-control w-full p-3 text-gray-700 bg-white border border-solid border-gray-300`,{borderRadius:23}]}
            placeholder="Buscar"
            onChangeText={
            (text) =>{
            searchFilterFunction(text);
            setFiltro(text);
            }}
        />
        <AntDesign name="search1" size={20} color={'#7D7D7D'} style={tw`absolute right-4 top-2.5`}/>
        </View>

        <FlatList
            data={filtro ? filteredData : colaboradores}
            keyExtractor={(item) => item.id}
            renderItem={({item})=>(
            <TouchableOpacity style={[tw`p-3 mt-2 border border-gray-300 flex-row shadow-md`,{borderRadius:16}]} key={item.id}>
                <View style={tw`items-center justify-center`}>
                        <ColaboradorAvatar size={"4rem"} foto={item.foto} key={item.id} />
                </View>
                <View style={tw`ml-2`}>
                    <Text style={tw`text-lg capitalize`}>{`${item.nombre} ${item.apellido}`}</Text>
                    <Text style={tw`mt-1`}>
                        <FontAwesome5 name="building" size={10} color={Colores.primary} style={tw`mr-1`}/>
                        {item.puesto_actual.nombre ? item.puesto_actual.nombre : '-'}
                    </Text>
                    <Text style={tw`mt-1`}>
                        <FontAwesome5 name="envelope" size={10} color={Colores.primary} style={tw`mr-1`}/>
                        {item.empresa_actual.nombre ? item.empresa_actual.nombre : '-'}
                    </Text>
                </View>
            </TouchableOpacity>
            )}
        />

        </View>
      <View style={tw`flex flex-row w-full`}>
          
          <View style={tw`pl-5`}>
            <View>
                <Text style={tw`mt-5 text-lg text-gray-900 mb-3`}>Sancion</Text>
                
                <Picker
                    selectedValue={tipoSancion}
                    style={{paddingVertical:10, paddingHorizontal:5, borderRadius:50}}
                    onValueChange={(itemValue, itemIndex) =>
                      setTipoSancion(itemValue)
                    }>
                    {tiposSanciones.map((item) => (
                        <Picker.Item label={item.tipo} value={item.id} key={item.id} />
                    ))}
                </Picker>
            </View>

            <View>
                <Text style={tw`mt-5 text-lg text-gray-900 mb-3`}>Motivo</Text>

                <Picker
                    selectedValue={tipoMotivo}
                    style={{paddingVertical:10, paddingHorizontal:5, borderRadius:50}}
                    onValueChange={(itemValue, itemIndex) =>
                      setTipoMotivo(itemValue)
                    }>
                    {motivosSanciones.map((item) => (
                        <Picker.Item label={item.motivo} value={item.id} />
                    ))}
                </Picker>
            </View>
            
          
          </View>

      </View>

        <View style={tw`mt-3 items-center justify-center`}>
            <Pressable onPress={pickDocument} foreground={true}>
              {foto ? (
                <Image
                  style={[tw`rounded-full h-30 w-30` , { resizeMode:'contain'}]}
                  source={{ uri: foto.uri }}
                />
              ) : (
                <View style={tw`rounded-lg shadow-md items-center justify-center rounded-full h-30 w-30`}>
                    <MaterialCommunityIcons name="image-plus" size={44} style={{color:Colores.primaryLight}} />
                </View>
              )}

          </Pressable>
          </View>


        {nuevoBeneficioMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <View style={tw`items-center justify-center`}>
            <TouchableOpacity onPress={handleSubmit(onSubmit)} style={[tw`mt-7 px-20 py-2.5 rounded shadow-md`,{backgroundColor:Colores.primary, borderRadius:16}]}>
                <Text style={tw`text-white text-center`}>Guardar</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>

    </ScrollView>
      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor="green"
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar Beneficios");
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar Beneficios");
        }}
      />
    </PageContainer>

  );
};
