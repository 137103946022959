import React, { useState } from "react";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import { Picker  } from "@react-native-picker/picker";
import tw from 'twrnc';

import { getLicenciasOpciones } from "../../services/apiClient";
import { Loader } from "../Loader";
import { Colores } from "../../constants/colores";

function LicenciaOpciones({ opcionSeleccionada }) {
  const { data, isLoading, isError } = useQuery(
    "licenciaOpciones",
    getLicenciasOpciones
  );
  const [opcionId, setopcionId] = useState();
  const opciones = [];
  if (data && data?.data.licenciaOpciones.length) {
    data.data.licenciaOpciones.map((opcion) => {
      opciones.push(
        < Picker.Item
          key={`opcion-${opcion.id}`}
          label={opcion.opcion}
          value={opcion.id}
        />
      );
    });

  }
  

  return (
    <View>
      {isLoading ? (
        <Loader flex={0} text="Cargando opciones" />
      ) : isError ? (
        <Text>Error al cargar opciones</Text>
      ) : (
        <Picker
          style={[tw` mt-2 w-full p-3 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary}]}
          selectedValue={opcionId}
          onValueChange={(itemValue, itemIndex) => {
            setopcionId(itemValue);
            opcionSeleccionada(data.data.licenciaOpciones[itemIndex - 1]); //setea la opcion en el nodo padre
          }}
        >
          < Picker.Item
            key={`opcion-0`}
            label="Seleccione una opcion"
            value={null}
          />
          {opciones.map((opcion) => opcion)}
        </ Picker>
      )}
    </View>
  );
}

export default LicenciaOpciones;
