import React from "react";
import {
  Ionicons,
  AntDesign,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { View, Text, TouchableOpacity } from "react-native";
import tw from "twrnc";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  descargarArchivoItem,
  eliminarArchivoItem,
} from "../../services/apiClient";
import { Loader } from "../Loader";
import ResultadoModal from "../ResultadoModal";

const iconos = {
  pdf: <AntDesign name="pdffile1" size={24} color="red" />,
  png: <MaterialIcons name="image" size={24} color="blue" />,
  jpg: <MaterialIcons name="image" size={24} color="blue" />,
  jpeg: <MaterialIcons name="image" size={24} color="blue" />,
  "vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <AntDesign name="wordfile1" size={24} color="red" />
  ),
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
    <MaterialCommunityIcons name="microsoft-excel" size={24} color="green" />
  ),
};

const ArchivoRemotoItem = ({ index, file, setmostrarAlert }) => {
  const queryClient = useQueryClient();

  const { isLoading, isError, refetch } = useQuery(
    "archivo",
    () => descargarArchivoItem(file.id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  const { archivo, extension, id, size } = file;
  // const confirmar = () => {
  //   setmostrarAlert({
  //     show: true,
  //     showProgress: eliminarMutate.isLoading,
  //     title: "Atención",
  //     message: "Esta seguro de desea eliminar el archivo?",
  //     showCancelButton: true,
  //     showConfirmButton: true,
  //     confirmText: "Eliminar",
  //     confirmButtonColor: "#DD6B55",
  //     onConfirmPressed: () => {
  //       eliminarMutate.mutate(id);
  //       setmostrarAlert({});
  //     },
  //     onDismiss: () => {
  //       setmostrarAlert({});
  //     },
  //     onSettled: (data) => {
  //       if (data.data.ok) {
  //         setmostrarAlert({});
  //       }
  //     },
  //   });
  // };

  const resetAwesomeAlert = {
    show: false,
    showProgress: false,
    title: null,
    message: null,
    showCancelButton: false,
    showConfirmButton: false,
    confirmText: "Aceptar",
    confirmButtonColor: "green",
    customView: null,
    onDismiss: () => {},
    onCancelPressed: () => {},
    onConfirmPressed: () => {},
  };

  const eliminarMutate = useMutation(eliminarArchivoItem, {
    onSuccess: async (response) => {
      setmostrarAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          setmostrarAlert(resetAwesomeAlert);
          queryClient.invalidateQueries("items");
        },
        onDismiss: () => {
          setmostrarAlert(resetAwesomeAlert);
        },
      });
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }

      setmostrarAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          setmostrarAlert(resetAwesomeAlert);
        },
        onDismiss: () => {
          setmostrarAlert(resetAwesomeAlert);
        },
      });
    },
  });

  return (
    <View
      key={index}
      style={tw`bg-white p-2 my-2 flex flex-row items-center gap-2 w-full max-w-[300px] border border-gray-200 rounded-md`}
    >
      <View style={tw`border border-gray-200 rounded-md p-1`}>
        {extension ? iconos[extension] : null}
      </View>
      <Text ellipsizeMode="tail" numberOfLines={1} style={tw`flex-1`}>
        {archivo}
      </Text>
      {isLoading ? (
        <Loader />
      ) : (
        <TouchableOpacity onPress={() => refetch()}>
          <Ionicons name="cloud-download-outline" size={24} color="red" />
        </TouchableOpacity>
      )}

      {eliminarMutate.isLoading ? (
        <Loader />
      ) : (
        <TouchableOpacity onPress={() => eliminarMutate.mutate(id)}>
          <Ionicons name="trash-outline" size={24} color="red" />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ArchivoRemotoItem;