import { View, Text, useWindowDimensions, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import { useQuery } from 'react-query';
import { getFixture } from '../../services/apiClient';
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { LinearGradient } from "expo-linear-gradient";


const PartidosProde = ({props}) => {
    const fixture = useQuery("fixture",getFixture);
    const { width } = useWindowDimensions()

    const filtrarInfoGrupos = (grupo) =>{ 
        const informacion = fixture?.data?.data.fixture.filter((item)=> item.grupo === grupo);
        props.navigation.navigate("Detalle Prode",{informacion})
    }

  return (
    <View>

          <View style={tw`items-center justify-center mt-4 mb-1`}>
            <Text style={tw`text-lg font-semibold`}>TUS PRONOSTICOS</Text>
          </View>

          <LinearGradient style={tw`px-3 my-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>GRUPOS </Text>
          </LinearGradient>

          <View style={tw`px-2 mb-10`}>

            {/* <TouchableOpacity style={tw`flex-row border border-gray-200 p-2 rounded-lg shadow-md`} onPress={()=>props.navigation.navigate("Detalle Prode")}> */}
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("A")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO A</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("B")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO B</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("C")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO C</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("D")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO D</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("E")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO E</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("F")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO F</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("G")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO G</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("H")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO H</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>

          </View>
    </View>
  )
}

export default PartidosProde