import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  ActivityIndicator,
  Image,
  Pressable,
  Text,
  TextInput,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import FechaInicioFin from "../components/Licencias/FechaInicioFin";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { MaterialIcons } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";
import tw from "twrnc";
import { FontAwesome } from "@expo/vector-icons";

import LicenciaOpciones from "../components/Licencias/LicenciaOpciones";
import Advertencia from "../components/Licencias/Advertencia";
import { nuevaLicencia } from "../services/apiClient";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { Colores } from "../constants/colores";
import { esWeb } from "../utils/plataforma";
import usePermisos from "../hooks/usePermisos";

function LicenciaForm({
  mostrarAlert,
  setmostrarAlert,
  colaboradorAsignado = null,
}) {
  const hoy = moment().format("yyyy/MM/DD");
  const [opcion, setopcion] = useState();
  const [certificado, setcertificado] = useState();
  const [colaborador, setcolaborador] = useState(colaboradorAsignado);
  const [fechaInicio, setfechaInicio] = useState(hoy);
  const [fechaFin, setfechaFin] = useState(hoy);
  const [advertenciaVisible, setadvertenciaVisible] = useState(false);
  const [dias, setdias] = useState(0);
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const { validarPermiso } = usePermisos();

  useEffect(() => {
    setcolaborador(colaboradorAsignado);
  }, [colaboradorAsignado]);

  const nuevaLicenciaMutate = useMutation(nuevaLicencia, {
    onSuccess: async (response) => {
      console.log("response", response);
      if (response.status === 403) {
        resetForm();
        setmostrarAlert({
          show: true,
          customView: (
            <ResultadoModal success={false} message={response.data.message} />
          ),
        });
        return;
      }

      queryClient.invalidateQueries("misLicencias");
      resetForm();
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="La Licencia fue creada" />
        ),
      });
    },
    onError: async (error) => {
      resetForm();
      if (error.response.status === 403) {
        setmostrarAlert({
          show: true,
          customView: (
            <ResultadoModal
              success={false}
              message={error.response?.data?.message}
            />
          ),
        });
        return;
      }

      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    reset();
    setdias(0);
    setopcion();
    setcertificado();
    setfechaInicio(hoy);
    setfechaFin(hoy);
    setadvertenciaVisible(false);
  };

  useEffect(() => {
    setdias(moment(fechaFin).diff(fechaInicio, "days"));
  }, [fechaInicio, fechaFin]);

  useEffect(() => {
    if (opcion) {
      setadvertenciaVisible(true);
    } else {
      setadvertenciaVisible(false);
    }
  }, [opcion]);

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    if (result.type === "success") {
      if (esWeb()) {
        setcertificado(result);
      } else {
        const archivoCertificado = {
          uri: result?.uri,
          name: result?.name,
          type: result?.mimeType,
        };
        setcertificado(archivoCertificado);
      }
    }
  };

  const onSubmit = (data) => {
    if (certificado) {
      if (esWeb()) {
        formData.append("certificado", certificado?.file);
      } else {
        formData.append("certificado", certificado);
      }
    }

    if (colaborador) {
      formData.append("colaborador_id", colaborador.id);
    }

    formData.append("opcion_id", opcion.id);
    formData.append("inicio", moment(fechaInicio).format("yyyy/MM/DD"));
    formData.append("fin", moment(fechaFin).format("yyyy/MM/DD"));
    //se cargan el resto de campos del formulario en formData
    console.log(dirtyFields);
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }
    nuevaLicenciaMutate.mutate(formData);
  };

  return (
    <View
      style={
        width >= 768 ? tw`flex flex-1 bg-white rounded-xl p-5` : tw`flex flex-1`
      }
    >
      {colaborador && (
        <View
          style={[
            tw`flex flex-row items-center justify-between bg-orange-200 px-4 py-2 rounded-md text-white mb-4`,
          ]}
        >
          <View style={[tw`flex flex-row items-center`]}>
            <Text>Esta por asignarle una licencia a </Text>
            <Text style={[tw`font-bold`]}>
              {colaborador.apellido}, {colaborador.nombre}
            </Text>
          </View>
          <TouchableOpacity
            style={[
              tw`flex flex-row items-center justify-center p-2 justify-center items-center text-cyan-600 rounded-sm`,
              { backgroundColor: Colores.warning },
            ]}
            onPress={() => {
              setcolaborador(null);
            }}
          >
            <Text style={[tw`text-white`]}>Cancelar</Text>
          </TouchableOpacity>
        </View>
      )}

      {width >= 768 && (
        <Text style={tw`text-lg font-semibold mb-5`}>Solicitar licencia</Text>
      )}
      <Text
        style={[
          tw`text-lg ml-1 text-center`,
          { textAlign: width >= 768 ? "left" : "center" },
        ]}
      >
        Elegí el tipo de licencia que vas a solicitar
      </Text>
      <LicenciaOpciones opcionSeleccionada={setopcion} />
      {!opcion && (
        <View style={tw`justify-center items-center flex-5`}>
          <FontAwesome name="id-card" size={250} color={Colores.primaryLight} />
        </View>
      )}
      <Advertencia visible={advertenciaVisible} licenciaTipo={opcion?.tipo} />

      {opcion?.tipo.tipo === "PROGRAMADA" ||
      opcion?.tipo.tipo === "NO PROGRAMADA" ? (
        <View style={tw`flex gap-4`}>
          <View>
            <Text style={tw``}>Motivo</Text>
            <Controller
              control={control}
              rules={{
                required: "Indique el motivo",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={tw` h-30 mt-2 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded`}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Indique el motivo"
                  value={value}
                />
              )}
              name="motivo"
            />
            {errors?.motivo && (
              <MensajeError message={errors.motivo?.message} />
            )}
          </View>
          <Text style={tw` mt-3 mb-2`}>Cargar Certificado</Text>
          <View style={tw`rounded-t-lg`}>
            {certificado && certificado?.uri ? (
              <Image
                style={tw`rounded-lg mb-2 h-[9rem] w-full`}
                source={{ uri: certificado.uri }}
              />
            ) : null}

            <Pressable onPress={pickDocument} foreground={true}>
              <View
                style={tw`flex flex-row items-center justify-center px-6 py-2.5 bg-cyan-600 uppercase rounded shadow-md mb-3`}
              >
                <MaterialIcons name="upload-file" size={24} color="white" />
                <Text style={tw` text-white ml-2`}>
                  Cargar certificado (png, jpg, jpeg)
                </Text>
              </View>
            </Pressable>
          </View>

          <View>
            <FechaInicioFin
              inicio={setfechaInicio}
              fin={setfechaFin}
              bloquearFechasPrevias={
                validarPermiso(["administrar.todo", "administrar.licencias"])
                  ? false
                  : true
              }
            />
            <Text style={tw`mt-3 `}>Total de días {dias}</Text>
          </View>
        </View>
      ) : null}

      {opcion?.tipo.tipo === "PERMISO LABORAL" && (
        <View>
          <View>
            <View>
              <Text>Motivo</Text>
              <Controller
                control={control}
                rules={{
                  required: "Indique el motivo",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={tw` h-30 mt-2 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded`}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="Indique el motivo"
                    value={value}
                  />
                )}
                name="motivo"
              />
              {errors?.motivo && (
                <MensajeError message={errors.motivo?.message} />
              )}
            </View>
            <View>
              <Text style={tw`mt-3 `}>Horario</Text>
              <Controller
                control={control}
                rules={{
                  required: "Indique un horario",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={tw` h-20 mt-2 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded`}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="Especifica el horario durante el cual estarás ausente"
                    value={value}
                  />
                )}
                name="horario"
              />
              {errors?.horario && (
                <MensajeError message={errors.horario?.message} />
              )}
            </View>
            <FechaInicioFin inicio={setfechaInicio} fin={setfechaFin} />
            <Text style={tw`mt-3 `}>Total de días {dias}</Text>
          </View>
        </View>
      )}

      {opcion?.tipo.tipo === "VACAIONES" && (
        <View style={tw`mt-5`}>
          <Text style={tw`text-lg mb-5`}>
            Selecciona las fechas de inicio y retorno para tus vacaciones
          </Text>
          <FechaInicioFin inicio={setfechaInicio} fin={setfechaFin} />
        </View>
      )}

      {nuevaLicenciaMutate.isLoading && !nuevaLicenciaMutate.isError ? (
        <ActivityIndicator size="small" color={Colores.primary} />
      ) : opcion ? (
        <View style={tw`items-center justify-center mb-10`}>
          <TouchableOpacity
            onPress={handleSubmit(onSubmit)}
            style={[
              tw`mt-7 px-20 py-2.5 shadow-md`,
              { backgroundColor: Colores.primary, borderRadius: 15 },
            ]}
          >
            <Text style={tw`text-white text-center`}>Enviar</Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
}

export default LicenciaForm;
